/**
 *  Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
	LIST_EDUCATION_INSTITUTE,
	VERFIY_EMAIL,
	PUBLIC_PASSORT,
	VERIFY_USER_DETAILS,
	CANCEL_ALL_API_REQUESTS
} from './constants';

/**
 * Set intial state
 */
const intialState = fromJS({
	isVerifyingEmail: true,
	educationInstitute: [],
	isfetchingPubProfile: true,
	publicProfile:{},
	verificationDetails:{},
	isFetchingVerificationDetails:false,
})

/**
 * Define the reducer with actions
 */
function authReducer(state = intialState, action) {
	switch (action.type){
		case success(LIST_EDUCATION_INSTITUTE):
			const { data } = action.payload.data;
			return fromJS({
				...state.toJS(),
				educationInstitute: data && data.length > 0 ? data : [],
			})


			case VERIFY_USER_DETAILS:
				return fromJS({
					...state.toJS(),
					isFetchingVerificationDetails: true,
			})
			case success(VERIFY_USER_DETAILS):
				const verifyData  = action.payload.data.data;
				return fromJS({
					...state.toJS(),
					verificationDetails: verifyData,
					isFetchingVerificationDetails: false,
				})
			case failure(VERIFY_USER_DETAILS):
				return fromJS({
					...state.toJS(),
					isFetchingVerificationDetails: false,
				})
	

			
    
    case CANCEL_ALL_API_REQUESTS:
      return fromJS({
        ...intialState.toJS()
      })
    default:
      return state
	}
}

/**
 * Export the reducer
 */
export default authReducer