/**
 * Import action creator constants & dependencies
 */
import {
	SOCIAL_LOGIN_CALLBACK
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
const cancelApiRequests = [];

/**
 *  API for Apply & Interest
 */
export function socialCallback(params, name) {
	return async (dispatch, getState) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				socialCallbackBegin(
					API_URLS.AUTH.SOCIAL_CALLBACK,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const socialCallbackBegin = (API_URLS, params, source) => ({
	type: SOCIAL_LOGIN_CALLBACK,
	payload: {
		request: {
			url: API_URLS,
			method: 'POST',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});
