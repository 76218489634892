import React, { Component , Fragment } from "react";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';


import Friends from '../components/Friends';
import Contacts from '../components/Contacts';
import Group from '../components/Group';
import Events from '../components/Events';
import Createpost from '../components/Createpost';
import Memberslider from '../components/Memberslider';
import Friendsilder from '../components/Friendsilder';
/* import Storyslider from '../components/Storyslider'; */
import Postview from '../components/Postview';
import Load from '../components/Load';
import Profilephoto from '../components/Profilephoto';
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";


import {
    getUserPosts,
  } from "../modules/profile/actions";
import { getIsUserPosts } from "../modules/profile/selectors";

class Home extends Component {
    state = {
        page: 1,
        comments: [],
        userComment: "",
        showCommentStatus: false,
        likeCount: [],
        editData: "",
        editPost: false,
        post: [],
        isPostLoading: false,
        showDeleteBox: false,
        showCommentDeleteBox: false,
        addCommentPostId: "",
        deletePostId: "",
        deleteCommentId: "",
        commentMode: this.props.mode === "edit" ? "Edit" : "Add",
        infiniteLoader: false,
        totalPostCount:0
      };

      handleScroll = (e) => {
        const { scrolling, totalPages, page, post } = this.state;
        
        if (e.target.id === "mySidenav") return;
        if (scrolling) return;
        if (totalPages <= page) return;
        if (post.length === 0) return;
        const lastDiv = document.querySelector(
          "div.postViewSection > div.Nous__Card__Post:last-child"
        ); 
        if (lastDiv !== null) {  
          var lastDivOffset = e.target.scrollTop + lastDiv.clientHeight; // Get The scroll Position of when user scroll
          const pageOffset = e.target.scrollHeight - e.target.offsetHeight; // Get The Height when API Load
          var bottomOffset = 150;
          console.log(lastDiv.clientHeight); 
          console.log(e.target.scrollTop); 
          if (lastDivOffset - bottomOffset > pageOffset) {
            this.setState({ infiniteLoader: true });
            this.loadMorePosts();
          }
        }
      };

      loadPosts = () => {
        const { page, per_page } = this.state;
        this.props.getUserPosts(page).then((res) => {
          
          
          let postData = res.data.data;
          /* console.log(postData);
          postData.map((data,index)=>{
            console.log(data.id,"inside map ")
          }); */
          this.setState({
            post: [...this.state.post, ...postData],
            scrolling: false,
            //totalPages: res.data.meta ? res.data.meta.total_pages : 0,
            totalPostCount: res.data.total_rows ? res.data.total_rows : 0,
          });

           


        }).catch((err) => {
        //showErrorMsg("Something Went Wrong");
        });
      };

      loadMorePosts = () => {
        this.setState((prevState) => ({
          page: prevState.page + 1,
          scrolling: true,
        }));
        this.props.getUserPosts(this.state.page).then((res) => {
          let postData = res.data.data;
          this.setState({
            post: [...this.state.post, ...postData],
            scrolling: false,
            infiniteLoader: false,
          });
        });
      };

      // Loading Posts at Page Load
    componentDidMount() {
        let page = this.props.location.pathname + this.props.location.search;
        //trackPage(page);
        this.loadPosts();

        this.scrollListener = window.addEventListener(
        "scroll",
        (e) => {
            this.handleScroll(e);
        },
        true
        ); 
    }
    componentWillUnmount() {
      this.scrollListener = window.removeEventListener(
        "scroll",
        (e) => {
          this.handleScroll(e);
        },
        true
      );
    }
    render() {
        let {
            comments,
            userComment,
            like_count,
            editData,
            mode,
            infiniteLoader,
            showCommentDeleteBox,
            showDeleteBox,
            post,
            isPostLoading,
            totalPostCount
          } = this.state;
        const { userPostAll, isUserPosts, isVisible, onClose } = this.props;
        /* console.log(post);
           post.map((data,index)=>{
            console.log(data.id,"inside render ")
          });  */
        return (
            <Fragment> 
                <Header />
                <Leftnav />
                <Rightchat />

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-8 col-xxl-9 col-lg-8">
                                    {/* <Storyslider /> */}
                                    {/* <Createpost /> */}
                                    <Createpost
                                    {...this.props}
                                    receivePostFromCreate={(newlyaddPost) =>
                                        this.receivePostFromCreate(newlyaddPost)
                                    }
                                    />
                                    <div className="postViewSection">
                                      { 
                                        post.map((data, index) => 
                                          
                                           ( <Postview 
                                                index={index}
                                                id={data.id}
                                                postvideo={data.post_videos}
                                                postimage={data.post_image}
                                                avater={data.post_content}
                                                user={data.post_content}
                                                time={data.post_content}
                                                des={data.post_content}
                                                comment_count={data.comment_count}
                                                like_count={data.like_count}
                                            />
                                         )
                                        )

                                      }       
                                      
                                    </div>
                                    <Load />
                                </div>
                                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                    <Friends />
                                    {/* <Contacts />
                                     <Group />
                                    <Events />
                                    <Profilephoto /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Popupchat />
                <Appfooter /> 
            </Fragment>
        );
    }
}

//export default Home;


//export default Createpost;


/**
 *  Define component PropTypes
 */
 Home.propTypes = {
    //isUploadingImage: bool.isRequired,
  };
  
  /**
   *  Map redux state to component props
   */
  const mapStateToProps = createStructuredSelector({
    //isUploadingImage: getIsUploadingImage(),
    isUserPosts: getIsUserPosts(),
    //userPostAll: getUserPostAll(),
  });
  export default withRouter(
    connect(mapStateToProps, {
      getUserPosts,
    })(Home)
  );