/**
 * Import node Modules
 */
import { createSelector } from 'reselect';

/**
 * Select the portion of the root reducer
 */
export const profile = () => state => state.get('profile');


  /**
 *  GET USER DETAILS LEFT SIDE SELECTOR
 */

   export const getUserDetails = () =>
   createSelector(profile(), state => {
     const userDetails = state.get('userDetails').toJS()
     return userDetails ? userDetails : {}
   })

/**
 * Select the correct portion of the root reducer
 */
export const getInviteLinkDataListing = () =>
  createSelector(profile(), state => {
    const listing = state.get('inviteLinkData').toJS()
    return listing
  });

/**
 * Select the correct portion of the root reducer
 */
export const getUserDataListing = () =>
  createSelector(profile(), state => {
    const listing = state.get('userData').toJS()
    return listing ? listing : {}
  });

/**
* Select User Posts
*/
export const getUserPostAll = () =>
  createSelector(profile(), state => {
    const userPosts = state.get('UserPosts').toJS()
    // return Object.keys(userPosts).length > 0 ? userPosts : {}
    return userPosts ? userPosts : {}
  });

/**
 * Select Keep in Touch area
 */
export const getKeepInTouch = () =>
  createSelector(profile(), state => {
    const keepinTouch = state.get('KeepTouchData').toJS()
    return keepinTouch ? keepinTouch : {}
  });

/**
 * Select Get All Keep in Touch area
 */
export const getAllKeepInTouch = () =>
  createSelector(profile(), state => {
    const keepinTouch = state.get('getAllKeepTouchData').toJS()
    return keepinTouch && keepinTouch.length > 0 ? keepinTouch : []
  });
/**
 * Select Relations Data for People connection and Business Connection
 */
export const getConnectionsRelation = () =>
  createSelector(profile(), state => {
    const listing = state.get('relationsData').toJS()
    return listing && listing.length > 0 ? listing : []
  });

/**
 * Select Pending user requests
 */
export const getPendingRequests = () =>
  createSelector(profile(), state => {
    const listing = state.get('pendingRequests').toJS()
    return listing && listing.length > 0 ? listing : []
  });
/**
 * Select Search Suggestions
 */
export const searchSuggestionsList = () =>
  createSelector(profile(), state => {
    const listing = state.get('searchSuggestions').toJS()
    return listing ? listing : {}
  });
/**
 * Select Search Data
 */
export const searchDataList= () =>
  createSelector(profile(), state => {
    const listing = state.get('searchData').toJS()
    return listing ? listing : {}
  });
/**
 * Select Viewers Data
 */
export const viewersData = () =>
  createSelector(profile(), state => {
    const listing = state.get('viewerData').toJS()
    return listing ? listing : {}
  });
/**
 * Select Random Organisation Data
 */
export const randomOrganisations = () =>
  createSelector(profile(), state => {
    const listing = state.get('randomOrganisations').toJS()
    return listing ? listing : {}
  });
/**
 * Select All Notifications
 */
export const allNotifications = () =>
  createSelector(profile(), state => {
    const listing = state.get('allNotifications').toJS()
    return listing ? listing : {}
  });

/**
 * Select the User Fetching Data 
 */
export const getIsUserData = () =>
  createSelector(profile(), state => {
    return state.get('isUserData')
  });
/**
 * Select the User Posts Data 
 */
export const getIsUserPosts = () =>
  createSelector(profile(), state => {
    return state.get('isUserPost')
  });

/**
 * Select the Keep in touch Data 
 */
export const IsKeepInTouch = () =>
  createSelector(profile(), state => {
    return state.get('isKeepTouch')
  });

/**
 *  Select is Uploading image
 */
export const getIsUploadingImage = () =>
  createSelector(profile(), state => {
    return state.get('isUploadingImage')
  })

/**
 * Select the Keep in touch Data 
 */
export const IsGetAllKeepInTouch = () =>
  createSelector(profile(), state => {
    return state.get('isGetAllKeepTouch')
  });

/**
 * Fetching Relations Data
 */
export const IsFetchingRelationsData = () =>
  createSelector(profile(), state => {
    return state.get('isRelationsData')
  });

/**
 * Fetching Pending Requests Data
 */
export const IsFetchingPendingRequests = () =>
  createSelector(profile(), state => {
    return state.get('isPendingRequests')
  });
/**
 * Fetching Suggestions Data
 */
export const IsFetchingSuggestions = () =>
  createSelector(profile(), state => {
    return state.get('isSuggestionsData')
  });
/**
 * Fetching Viewed Data
 */
export const IsFetchingViewedData = () =>
  createSelector(profile(), state => {
    return state.get('isViewerData')
  });
/**
 * Fetching SearchData Data
 */
export const isSearchData = () =>
  createSelector(profile(), state => {
    return state.get('isSearchData')
  });
/**
 * Fetching random organisation Data
 */
export const isRandomOrganisation = () =>
  createSelector(profile(), state => {
    return state.get('isRandomOrganisations')
  });
/**
 * Fetching random organisation Data
 */
export const isNotifications = () =>
  createSelector(profile(), state => {
    return state.get('isNotifications')
  });

/**
 * Fetching random organisation Data
 */
export const getIsInviteLinkDataFetching = () =>
  createSelector(profile(), state => {
    return state.get('isInviteLinkDataFetching')
  });


