import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

class LoadSkeleton extends Component {
  render() {
    let { type, RemovePreview } = this.props;
    return (
      <>
        {type === "InviteLink" && (
          <div className="link-previewCard">
            <div className="row mt-3 mb-4">
              <div className="col-md-12">
                <p className="w-100 px-2 mb-0">
                  <Skeleton height={20} />
                </p>
              </div>
            </div>
          </div>
        )}

        {type === "crawl" && (
          <div className="link-previewCard">
            <div className="text-right mr-2 mb-1">
              <span className="cross" onClick={RemovePreview}></span>
            </div>
            <div className="row mt-3 mb-4">
              <div className="col-md-4 text-center">
                <Skeleton circle={true} height={100} width={100} />
              </div>
              <div className="col-md-6">
                <p className="preview-Description px-2">
                  <Skeleton height={20} />
                </p>
                <p className="preview-Description px-2">
                  <Skeleton height={10} />
                  <Skeleton height={10} />
                </p>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        )}

        {type === "chatinit" && (
          <>
            <div className="row chat-list py-1">
              <div className="col-lg-3 col-md-1 col-sm-1 col-2 px-xl-0 text-center">
                <Skeleton circle={true} height={50} width={50} />
              </div>
              <div className="col-lg-9 col-md-11 col-sm-11 col-10 ">
                <h6 className="user_name">
                  <Skeleton height={15} width={130} />
                </h6>
                <p className="user_subject">
                  <Skeleton height={10} width={80} />
                </p>
              </div>
            </div>
            <div className="row chat-list py-1">
              <div className="col-lg-3 col-md-1 col-sm-1 col-2 px-xl-0 text-center">
                <Skeleton circle={true} height={50} width={50} />
              </div>
              <div className="col-lg-9 col-md-11 col-sm-11 col-10 ">
                <h6 className="user_name">
                  <Skeleton height={15} width={130} />
                </h6>
                <p className="user_subject">
                  <Skeleton height={10} width={80} />
                </p>
              </div>
            </div>
            <div className="row chat-list py-1">
              <div className="col-lg-3 col-md-1 col-sm-1 col-2 px-xl-0 text-center">
                <Skeleton circle={true} height={50} width={50} />
              </div>
              <div className="col-lg-9 col-md-11 col-sm-11 col-10 ">
                <h6 className="user_name">
                  <Skeleton height={15} width={130} />
                </h6>
                <p className="user_subject">
                  <Skeleton height={10} width={80} />
                </p>
              </div>
            </div>
          </>
        )}

        {type === "post" && (
          <div className="Nous__Card__Post">
            <div className="col-md-12">
              <div className="row ">
                <div className="col-xl-1 col-lg-2 col-md-1 col-sm-1 col-2 text-right">
                  <Skeleton
                    duration={0.9}
                    circle={true}
                    width={40}
                    height={40}
                  />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                  <p className="dual-description mb-0 mt-1 text-capitalize open-sans">
                    <Skeleton height={15} duration={0.9} />
                    <Skeleton height={10} duration={0.9} width={100} />
                  </p>
                </div>
                <div className="col-xl-8 col-lg-7 col-md-8 col-sm-8 col-7"></div>
              </div>
              <hr className="my-2" />
            </div>
            <div className="col-md-12">
              <p className="post-text mb-2 mt-4">
                <Skeleton height={200} duration={0.9} />
              </p>
            </div>
          </div>
        )}

        {type === "profile" && (
          <div className="row">
            <div className="col-12">
              <div className="py-5 px-5">
                <div className="mx-auto text-center">
                  <Skeleton circle={true} height={80} width={80} />
                </div>
                <div className="mt-4">
                  <Skeleton height={20} />
                  <Skeleton height={10} />
                </div>
                <div className="py-4"></div>
              </div>
            </div>
          </div>
        )}

        {type === "search" && (
          <>
            <div className="searchResults">
              <h1 className="searchHeading">People</h1>
              <div className="results">
                <div className="row people-list px-1">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                    <div className="user-round-image nous-object-fit">
                      <Skeleton
                        circle={true}
                        width={50}
                        height={50}
                        duration={5}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5 mt-2">
                    <h6 className="user_name mt-1">
                      <Skeleton duration={5} />
                    </h6>
                    <p className="user_subject">
                      <Skeleton height={10} width={100} duration={5} />
                    </p>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5"></div>
                </div>
              </div>
              <div className="results">
                <div className="row people-list px-1">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                    <div className="user-round-image nous-object-fit">
                      <Skeleton
                        circle={true}
                        width={50}
                        height={50}
                        duration={5}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5 mt-2">
                    <h6 className="user_name mt-1">
                      <Skeleton duration={5} />
                    </h6>
                    <p className="user_subject">
                      <Skeleton height={10} width={100} duration={5} />
                    </p>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5"></div>
                </div>
              </div>
            </div>
            <div className="searchResults">
              <h1 className="searchHeading">Organisations</h1>
              <div className="results">
                <div className="row people-list px-1">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                    <div className="user-round-image nous-object-fit">
                      <Skeleton
                        circle={true}
                        width={50}
                        height={50}
                        duration={5}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5 mt-2">
                    <h6 className="user_name mt-1">
                      <Skeleton duration={5} />
                    </h6>
                    <p className="user_subject">
                      <Skeleton height={10} width={100} duration={5} />
                    </p>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5"></div>
                </div>
              </div>
              <div className="results">
                <div className="row people-list px-1">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                    <div className="user-round-image nous-object-fit">
                      <Skeleton
                        circle={true}
                        width={50}
                        height={50}
                        duration={5}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5 mt-2">
                    <h6 className="user_name mt-1">
                      <Skeleton duration={5} />
                    </h6>
                    <p className="user_subject">
                      <Skeleton height={10} width={100} duration={5} />
                    </p>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-5"></div>
                </div>
              </div>
            </div>
          </>
        )}

        {type === "listText" && (
          <div className="px-2">
            <div className="searchResults">
              <div className="results">
                <div className="row people-list px-1">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 ">
                    <div className="user-round-image nous-object-fit">
                      <Skeleton
                        circle={true}
                        width={50}
                        height={50}
                        duration={5}
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-7 col-7 mt-2">
                    <h6 className="user_name mt-1">
                      <Skeleton duration={5} />
                    </h6>
                    <p className="user_subject">
                      <Skeleton height={10} width={100} duration={5} />
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-3"></div>
                </div>
              </div>
            </div>
            <div className="searchResults">
              <div className="results">
                <div className="row people-list px-1">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                    <div className="user-round-image nous-object-fit">
                      <Skeleton
                        circle={true}
                        width={50}
                        height={50}
                        duration={5}
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-7 col-7 mt-2">
                    <h6 className="user_name mt-1">
                      <Skeleton duration={5} />
                    </h6>
                    <p className="user_subject">
                      <Skeleton height={10} width={100} duration={5} />
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-3"></div>
                </div>
              </div>
            </div>
            <p
              className="post-text px-2 mt-2 text-center"
              style={{ color: "#D3D3D3" }}
            >
              Hold on! We are fetching data.
            </p>
          </div>
        )}

        {type === "listTextSuggested" && (
          <div className="px-2">
            <div className="searchResults">
              <div className="results">
                <div className="row people-list px-1">
                  <div className="col-lg-3 col-md-2 col-sm-2 col-2 ">
                    <div className="user-round-image nous-object-fit">
                      <Skeleton
                        circle={true}
                        width={50}
                        height={50}
                        duration={5}
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-7 col-7 mt-2">
                    <h6 className="user_name mt-1">
                      <Skeleton duration={5} />
                    </h6>
                    <p className="user_subject">
                      <Skeleton height={10} width={100} duration={5} />
                    </p>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-3 col-3"></div>
                </div>
              </div>
            </div>
            <div className="searchResults">
              <div className="results">
                <div className="row people-list px-1">
                  <div className="col-lg-3 col-md-2 col-sm-2 col-2 text-right">
                    <div className="user-round-image nous-object-fit">
                      <Skeleton
                        circle={true}
                        width={50}
                        height={50}
                        duration={5}
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-7 col-7 mt-2">
                    <h6 className="user_name mt-1">
                      <Skeleton duration={5} />
                    </h6>
                    <p className="user_subject">
                      <Skeleton height={10} width={100} duration={5} />
                    </p>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-3 col-3"></div>
                </div>
              </div>
            </div>
            <p
              className="post-text px-2 mt-2 text-center"
              style={{ color: "#D3D3D3" }}
            >
              Hold on! We are fetching data.
            </p>
          </div>
        )}

        {type === "addconnection" && (
          <>
            <div class="col-4 px-2 mb-2">
              <div class="pymkcard">
                <div class="row">
                  <div class="col-4">
                    <Skeleton circle={true} height={75} width={75} />
                  </div>
                  <div class="col-8 mt-3">
                    <h3 class="pymk-name">
                      {" "}
                      <Skeleton height={20} />
                    </h3>
                    <p class="pymk-university mb-0">
                      <Skeleton height={10} />
                    </p>
                    <p class="pymk-location">
                      <Skeleton height={10} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 px-2 mb-2">
              <div class="pymkcard">
                <div class="row">
                  <div class="col-4">
                    <Skeleton circle={true} height={75} width={75} />
                  </div>
                  <div class="col-8 mt-3">
                    <h3 class="pymk-name">
                      {" "}
                      <Skeleton height={20} />
                    </h3>
                    <p class="pymk-university mb-0">
                      <Skeleton height={10} />
                    </p>
                    <p class="pymk-location">
                      <Skeleton height={10} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 px-2 mb-2">
              <div class="pymkcard">
                <div class="row">
                  <div class="col-4">
                    <Skeleton circle={true} height={75} width={75} />
                  </div>
                  <div class="col-8 mt-3">
                    <h3 class="pymk-name">
                      {" "}
                      <Skeleton height={20} />
                    </h3>
                    <p class="pymk-university mb-0">
                      <Skeleton height={10} />
                    </p>
                    <p class="pymk-location">
                      <Skeleton height={10} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {type === "filterOptions" && (
          <>
            <li className="options">
              <Skeleton duration={20} width={150} />
            </li>
            <li className="options">
              <Skeleton duration={20} width={150} />
            </li>
            <li className="options">
              <Skeleton duration={20} width={150} />
            </li>
          </>
        )}
      </>
    );
  }
}

export default LoadSkeleton;
