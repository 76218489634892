/**
 * Import mode modules
 */
import { combineReducers } from 'redux-immutable';
/**
 * Import reducers
 * All reducers used in the app must be declared here!
 */

import authReducer from './modules/auth/reducer';
import profileReducer from './modules/profile/reducer';

/**
 *  Combine the reducers
 */
const reducers = combineReducers({
	auth: authReducer,
	profile: profileReducer,
});

/**
 *  Export the combined reducers
 */
export default reducers;