export const LOGIN_USER = 
	'src/modules/auth/LOGIN_USER';
export const FORGOT_PASSWORD = 
	'src/modules/auth/FORGOT_PASSWORD';
export const VERIFY_STUDENT_EMAIL = 
	'src/modules/auth/VERIFY_STUDENT_EMAIL';
export const VERIFY_STUDENT_SECONDARY_EMAIL = 
	'src/modules/auth/VERIFY_STUDENT_EMAIL';
export const ADDITIONAL_DETAILS = 
	'src/modules/auth/ADDITIONAL_DETAILS';
export const RESET_PASSWORDS = 
	'src/modules/auth/RESET_PASSWORDS';
export const RESEND_VERIFICATION_EMAIL = 
	'src/modules/auth/RESEND_VERIFICATION_EMAIL';
export const CANCEL_ALL_API_REQUESTS = 
	'src/modules/auth/CANCEL_ALL_API_REQUESTS';
export const LIST_EDUCATION_INSTITUTE = 
	'src/modules/auth/LIST_EDUCATION_INSTITUTE';
export const LOGOUT_USER = 
	'src/modules/auth/LOGOUT_USER';
export const VERIFY_USER_DETAILS = 
	'src/modules/auth/VERIFY_USER_DETAILS';
export const BASIC_DETAILS = 
	'src/modules/auth/BASIC_DETAILS';