export const UPDATE_PROFILE = 
	'src/modules/userProfile/UPDATE_PROFILE';
export const GET_USER_DATA = 
	'src/modules/userProfile/GET_USER_DATA';
export const GET_USER_DETAILS = 
	'src/modules/userProfile/GET_USER_DATA';
export const GET_PRACTICE = 
	'src/modules/userProfile/GET_PRACTICE';
export const GET_INSTITUTE_NAME = 
	'src/modules/userProfile/GET_INSTITUTE_NAME';
export const UPLOAD_IMAGE = 
	'src/modules/userProfile/UPLOAD_IMAGE';
export const CANCEL_ALL_API_REQUESTS = 
	'src/modules/userProfile/CANCEL_ALL_API_REQUESTS';
// SEND INVITE TO FRIENDS
export const SEND_INVITE = 
	'src/modules/userProfile/SEND_INVITE';
// GET USER POSTS
export const GET_USER_POSTS = 
	'src/modules/userProfile/GET_USER_POSTS';
// CREATE NEW POST
export const CREATE_NEW_POST = 
	'src/modules/userProfile/CREATE_NEW_POST';
// LIKE POST
export const LIKE_POST = 
	'src/modules/userProfile/LIKE_POST';
// KEEP IN TOUCH - STREAM RIGHT
export const KEEP_IN_TOUCH = 
	'src/modules/userProfile/KEEP_IN_TOUCH';
// UPDATE THE POST USER 
export const UPDATE_POST = 
	'src/modules/userProfile/UPDATE_POST';
// DELETE THE POST USER 
export const DELETE_POST = 
	'src/modules/userProfile/DELETE_POST';
// MAKE USER COMMENTS
export const MAKE_USER_COMMENT = 
	'src/modules/userProfile/MAKE_USER_COMMENT';
// UPDATE USER COMMENTS
export const UPDATE_USER_COMMENT = 
	'src/modules/userProfile/UPDATE_USER_COMMENT';
// DELETE USER COMMENTS
export const DELETE_USER_COMMENT = 
	'src/modules/userProfile/DELETE_USER_COMMENT';
// LIKE USER COMMENTS
export const LIKE_USER_COMMENT = 
	'src/modules/userProfile/LIKE_USER_COMMENT';
// MAKE USER COMMENTS
export const MAKE_USER_SUBCOMMENT = 
	'src/modules/userProfile/MAKE_USER_SUBCOMMENT';
// UPDATE USER COMMENTS
export const UPDATE_USER_SUBCOMMENT = 
	'src/modules/userProfile/UPDATE_USER_SUBCOMMENT';
// DELETE USER COMMENTS
export const DELETE_USER_SUBCOMMENT = 
	'src/modules/userProfile/DELETE_USER_SUBCOMMENT';
// LIKE USER COMMENTS
export const LIKE_USER_SUBCOMMENT = 
	'src/modules/userProfile/LIKE_USER_SUBCOMMENT';
// GET LINK PREVIEW
export const GET_LINK_PREVIEW = 
	'src/modules/userProfile/GET_LINK_PREVIEW';
// SEARCH API
export const SEARCH_DATA = 
	'src/modules/userProfile/SEARCH_DATA';
// SEARCH SUGGESTIONS
export const SEARCH_SUGGESTIONS = 
'src/modules/userProfile/SEARCH_SUGGESTIONS';
// FOLLOW USER
export const FOLLOW_USER = 
	'src/modules/userProfile/FOLLOW_USER';
// UNFOLLOW USER
export const UNFOLLOW_USER = 
	'src/modules/userProfile/UNFOLLOW_USER';
// KEEP IN TOUCH ALL
export const KEEP_IN_TOUCH_ALL = 
	'src/modules/userProfile/KEEP_IN_TOUCH_ALL';
export const INCREMENT_USER_VIEW_COUNT = 
	'src/modules/userProfile/INCREMENT_USER_VIEW_COUNT';
// GET PEOPLE AND BUSINESS RELATIONS
export const GET_ALL_RELATIONS = 
	'src/modules/userProfile/GET_ALL_RELATIONS';
// GET ALL PENDING REQUESTS
export const GET_ALL_PENDING_REQUESTS = 
	'src/modules/userProfile/GET_ALL_PENDING_REQUESTS';
// GET ALL SENT REQUESTS
export const GET_ALL_SENT_REQUESTS = 
	'src/modules/userProfile/GET_ALL_SENT_REQUESTS';
// ACCEPT OR REJECT REQUEST
export const ACCEPT_OR_REJECT_REQUEST = 
	'src/modules/userProfile/ACCEPT_OR_REJECT_REQUEST';
// ALL VIEWERS
export const CHECK_ALL_VIEWERS = 
	'src/modules/userProfile/CHECK_ALL_VIEWERS';
// RANDOM ORGANISATONS
export const GET_RANDOM_ORGANISATIONS = 
	'src/modules/userProfile/GET_RANDOM_ORGANISATIONS';
// GET ALL NOTIFICATIONS
export const GET_ALL_NOTIFICATIONS = 
	'src/modules/userProfile/GET_ALL_NOTIFICATIONS';
// READ NOTIFICATIONS
export const READ_NOTIFICATIONS = 
	'src/modules/userProfile/READ_NOTIFICATIONS';
//=> Invite Link For User
export const INVITE_LINK_FOR_USER = 
	'src/modules/userProfile/INVITE_LINK_FOR_USER';
