import React, { Component , Fragment } from "react";
import { Link,withRouter } from "react-router-dom";
import HeaderLogo from "../components/HeaderLogo";
import HeaderNav from "../components/HeaderNav";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import Spinner from "../components/Loader";
import { func } from "prop-types";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { userSignUp } from "../modules/auth/actions";
import { getToken,getLocalStorage } from "../utils/helper";



const userSignUpSchema = Yup.object().shape({
    fullname: Yup.string().required("Please enter a your name"),
    username: Yup.string().required("Please enter a your name"),
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
    password: Yup.string()
      .min(
        8,
        "Please enter a password containing at least 1 upper case, 1 lower case, 1 numeric, and 1 special character."
      )
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
      )
      .required("Please enter password"),
    confirm_password: Yup.string()
      .required("Please confirm password")
      .oneOf(
        [Yup.ref("password"), null],
        "Passwords do not must match. Please try again"
      ),
    agree: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

class Register extends Component {

    componentWillMount() {
      const { history } = this.props;
      const token = getToken();
      const storage = getLocalStorage();
      if (history && token && storage) {
          history.push("/home");
          return;
      }
    }

    registerValuesSchema = () => {
        return {
          fullname: "",
          username: "",
          email: "",
          password: "",
          confirm_password: "",
          agree: false,
        };
      };
    
      userSignUp = (values, { setSubmitting, resetForm }) => {
        const { userSignUp, history } = this.props;
        
        var params = {
          fullname: values.fullname,
          username: values.username,
          email: values.email,
          password: values.password,
          confirm_password: values.confirm_password,
          agree: values.agree
        };
    
        userSignUp(params).then(
          (res) => {
            if (res.status) {
              showSuccessMsg("User Successfully Added");
              resetForm(this.userSignUpSchema);
              setSubmitting(false);
              this.setState({ ShowModal: true });
            } else {
              const error    = res.error;
              Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
              setSubmitting(false);
            }
          },
          (err) => {
            setSubmitting(false);
          }
        );
      };

    render() {
        return (
            <Fragment> 
                <div className="main-wrap">
                    <div className="row">
                        <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                        style={{backgroundImage: `url("/assets/images/mywow/Login-Bg.jpg")`}}>
                            <HeaderLogo logoColor = "white" />
                        </div>
                        <div className="col-xl-7 vh-100 align-items-center  bg-white rounded-3 overflow-hidden">
                        <div className = " text-right" style={{height: '15vh'}}> 
                                <HeaderNav />
                            </div>
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">
                                    <h2 className="fw-700 display1-size display2-md-size mb-4">Create <br />your account</h2>                        
                                    
                                    <Formik
                                        initialValues={this.registerValuesSchema()}
                                        validationSchema={userSignUpSchema}
                                        onSubmit={this.userSignUp}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form >
                                                
                                                <div className="form-group icon-input mb-3">
                                                    <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                                    <Field
                                                      type="text"
                                                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                      placeholder="Your Name"
                                                      name="fullname"
                                                    />
                                                    <ErrorMessage
                                                      name="fullname"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>
                                                <div className="form-group icon-input mb-3">
                                                    <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                                    <Field
                                                      type="text"
                                                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                      placeholder="Username"
                                                      name="username"
                                                      autoComplete="off"
                                                    />
                                                    <ErrorMessage
                                                      name="username"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>
                                                <div className="form-group icon-input mb-3">
                                                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                                    <Field
                                                      type="text"
                                                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                      placeholder="Your Email Address"
                                                      name="email"
                                                    />
                                                    <ErrorMessage
                                                      name="email"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>
                                                <div className="form-group icon-input mb-3">
                                                    <Field
                                                      type="password"
                                                      className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                      placeholder="Password"
                                                      name="password"
                                                    />
                                                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                    <ErrorMessage
                                                      name="password"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>
                                                <div className="form-group icon-input mb-1">
                                                    <Field
                                                      type="password"
                                                      className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                      placeholder="Confirm Password"
                                                      name="confirm_password"
                                                    />
                                                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                    <ErrorMessage
                                                      name="confirm_password"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>
                                                
                                                <div className="form-check text-left mb-3">
                                                    <Field
                                                      type="checkbox"
                                                      name="agree"
                                                      id="exampleCheck2"
                                                      className="form-check-input mt-2"
                                                    />
                                                    <label
                                                      htmlFor="exampleCheck2"
                                                      className="form-check-label font-xsss text-grey-500"
                                                    >
                                                      {" "}
                                                      Accept Term and Conditions{" "}
                                                      <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="textDecorationNone"
                                                        href="#"
                                                      >
                                                        Terms of use
                                                      </a>{" "}
                                                     
                                                    </label>
                                                  </div>
                                                  <ErrorMessage
                                                    name="agree"
                                                    component="div"
                                                    className="error-msg pl-2 mt-2 text-danger"
                                                  />
                                            
                                                <div className="col-sm-12 p-0 text-left">
                                                    <button
                                                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                    >
                                                        {isSubmitting && <Spinner size={true} />}
                                                        {!isSubmitting && <>Register</>}
                                                    </button>
                                                    
                                                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Already have account <Link to="/login" className="fw-700 ms-1">Login</Link></h6>
                                                </div>
                                            </Form>
                                    )}
                                    </Formik>
                                    
                                </div>
                            </div> 
                        </div>
                        
                    </div>
                </div>
            </Fragment>
        );
    }
}

//export default Register;

/**
 * Define Component ProTypes
 */
 Register.propTypes = {
  userSignUp: func.isRequired,
};

/**
 * Map Redux State to component props
 */
const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, {
  userSignUp,
})(withRouter(Register));