import React from 'react'
import {  Link  } from "react-router-dom";

function HeaderNav() {
  return (
    <>
        
        {/* <form action="#" className="mt-4 float-left header-search ">
            <div className="form-group mb-0 icon-input">
                <i className="feather-search font-sm text-grey-400"></i>
                <input type="text" placeholder="Start typing to search.." className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl  theme-dark-bg" />
            </div>
        </form> 
        <button className="nav-menu me-0 ms-auto"></button>*/}
        <Link to="/home" className="header-btn mt-4 d-none d-lg-inline-block fw-600 text-black font-xss p-2   text-center lh-20 rounded-xl">Wow Travel Forum</Link>
        <Link to="/home" className="header-btn mt-4 d-none d-lg-inline-block   fw-600 text-black font-xss p-2   text-center lh-20 rounded-xl">Travel Events</Link>
        <Link to="/about-us" className="header-btn mt-4 d-none d-lg-inline-block   fw-600 text-black font-xss p-2   text-center lh-20 rounded-xl">About Us</Link>
        <Link to="/contactinformation" className="header-btn mt-4 d-none d-lg-inline-block   fw-600 text-black font-xss p-2  text-center lh-20 rounded-xl">Contact</Link>
    </>
  )
}

export default HeaderNav