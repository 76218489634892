/**
 * Import action creator constants & dependencies
 */
import {
	LOGIN_USER,
	FORGOT_PASSWORD,
	RESET_PASSWORDS,
	ADDITIONAL_DETAILS,
	VERIFY_STUDENT_EMAIL,
	VERIFY_STUDENT_SECONDARY_EMAIL,
	RESEND_VERIFICATION_EMAIL,
	VERIFY_USER_DETAILS,
	LOGOUT_USER,
	BASIC_DETAILS,
	CANCEL_ALL_API_REQUESTS
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];
/**
 *  Login Users
 */
export function login(email, password){
	return async (dispatch) => {
		var params = {
			email,
			password,
		};
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				loginBegin(
					API_URLS.AUTH.LOGIN,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				console.log(response)
				return data;
			}

			throw response;
		} catch(error){
			throw error.error.response;
		}
	};
}

export const loginBegin = (API_URLS, params, source) => ({
	type: LOGIN_USER,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});
/**
 *  Forgot Password
 */
export function forgotPassword(params){
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				forgotPasswordBegin(
					API_URLS.AUTH.FORGOT,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch(error){
			throw error.error.response;
		}
	};
}

export const forgotPasswordBegin = (API_URLS, params, source) => ({
	type: FORGOT_PASSWORD,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});
/**
 *  Reset Password
 */
export function passwordReset(params){
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				passwordResetBegin(
					API_URLS.AUTH.RESET_PASSWORD,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch(error){
			throw error.error.response;
		}
	};
}

export const passwordResetBegin = (API_URLS, params, source) => ({
	type: RESET_PASSWORDS,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/**
 *  Add New Employee on Noustack
 */
export function employeeSignUp(params){
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				employeeSignUpBegin(
					API_URLS.AUTH.EMPLOYEE_SIGNUP,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch(error){
			throw error.error;
		}
	};
}

export const employeeSignUpBegin = (API_URLS, params, source) => ({
	type: LOGIN_USER,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/**
 *  Add New User on My WoW
 */
export function userSignUp(params){
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				userSignUpBegin(
					API_URLS.AUTH.USER_SIGNUP,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch(error){
			throw error.error;
		}
	};
}

export const userSignUpBegin = (API_URLS, params, source) => ({
	type: LOGIN_USER,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			paramsSerializer: function(params) {
				return qs.stringify(params, {arrayFormat: 'brackets'})
			},
			cancelToken: source.token
		}
	}
});


/**
 *  Verify email
 */
export function verifyEmail(params){
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				verifyEmailBegin(
					API_URLS.AUTH.VERIFY_EMAIL,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch(error){
			throw error.error.response;
		}
	};
}

export const verifyEmailBegin = (API_URLS, params, source) => ({
	type: VERIFY_STUDENT_EMAIL,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});


/**
 *  Verify Secondary email
 */
export function verifySecondaryEmail(params){
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				verifySecondaryEmailBegin(
					API_URLS.AUTH.VERIFY_SECONDARY_EMAIL,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch(error){
			throw error.error.response;
		}
	};
}

export const verifySecondaryEmailBegin = (API_URLS, params, source) => ({
	type: VERIFY_STUDENT_SECONDARY_EMAIL,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/**
 *  Add Additional details
 */
export function additionalDetails(params){
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				additionalDetailsBegin(
					API_URLS.AUTH.ADD_ADDITIONAL_DETAILS,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch(error){
			throw error.error.response;
		}
	};
}

export const additionalDetailsBegin = (API_URLS, params, source) => ({
	type: ADDITIONAL_DETAILS,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});


/**
 *  Resend verification email
 */
export function resendVerificationEmail(params){
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				resendVerificationEmailBegin(
					API_URLS.AUTH.RESEND_EMAIL,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch(error){
			throw error.error.response;
		}
	};
}

export const resendVerificationEmailBegin = (API_URLS, params, source) => ({
	type: RESEND_VERIFICATION_EMAIL,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/**
 *  Logout user
 */
export function logoutUser(params){
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try{
			const response = await dispatch(
				logoutUserBegin(
					API_URLS.AUTH.LOGOUT,
					params,
					source
				)
			);
			if(response.payload){
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch(error){
			throw error.error.response;
		}
	};
}

export const logoutUserBegin = (API_URLS, params, source) => ({
	type: LOGOUT_USER,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/**
 *  Get Profile User Data 
 */
export function checkUserDetails() {
	return async (dispatch, getState) => {
		const state = getState();
		const params = {}
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				checkUserDetailsBegin(
					API_URLS.AUTH.DETAIL_STATUS,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const checkUserDetailsBegin = (API_URLS, params, source) => ({
	type: VERIFY_USER_DETAILS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/**
 *  Get Profile User Data 
 */
export function passwordDetails() {
	return async (dispatch, getState) => {
		const state = getState();
		const params = {}
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				passwordDetailsBegin(
					API_URLS.AUTH.BASIC_USER_STATUS,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const passwordDetailsBegin = (API_URLS, params, source) => ({
	type: BASIC_DETAILS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


export function cancelAllApiRequests(){
	return dispatch => {
		cancelApiRequests.forEach(apiRequest => {
			apiRequest.cancel()
		})
		dispatch(cancelAllApiRequestsBegin())
	}
};

export const cancelAllApiRequestsBegin = () => ({
	type: CANCEL_ALL_API_REQUESTS
});