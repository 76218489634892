const PREFIX = {
	MYWOW: process.env.REACT_APP_BASE_URL,
	MYWOW_ADMIN: process.env.REACT_APP_ADMIN_BASE_URL
};

/**
 * URLS
 */
export const API_URLS = {
	/**
	 * Auth Url's
	 */
	AUTH:{
		LOGIN: PREFIX.MYWOW + '/auth/login',
		EMPLOYEE_SIGNUP: PREFIX.MYWOW + '/signup/employee',
		USER_SIGNUP: PREFIX.MYWOW + '/auth/register',
		FORGOT: PREFIX.MYWOW + '/user-forgot-password',
		RESET_PASSWORD: PREFIX.MYWOW + '/reset-user-password',
		VERIFY_EMAIL: PREFIX.MYWOW + '/verify-email',
		VERIFY_SECONDARY_EMAIL: PREFIX.MYWOW + '/verify-secondary-email',
		SOCIAL_CALLBACK: PREFIX.MYWOW + '/callback',
		ADD_ADDITIONAL_DETAILS: PREFIX.MYWOW + '/user/add_basic_details',
		RESEND_EMAIL: PREFIX.MYWOW + '/resend-verify-email',
		LOGOUT: PREFIX.MYWOW + '/logout-user',
		DETAIL_STATUS: PREFIX.MYWOW + '/user/get_details_status',
		BASIC_USER_STATUS: PREFIX.MYWOW + '/api/publish_status',
	},
	/**
	 * Student Url's
	 */
	DASHBOARD: {
		USER_CHANGE_PASSWORD: PREFIX.MYWOW + '/change_password',
		GET_LOGIN_SECURITY: PREFIX.MYWOW + '/get_user_emails',
		ADD_EDIT_EMAIL: PREFIX.MYWOW + '/add_new_email',
		REMOVE_EMAIL: PREFIX.MYWOW + '/remove_email',
		MAKE_PRIMARY_EMAIL: PREFIX.MYWOW + '/make_primary_email',
	},
	/* 
	* Create Post, Update Post, Delete Post, Get User Data, Like Post,
		Make Comment, Update Comment,Delete Comment,LikeComment,
		Make SubComment, Update SubComment,Delete SubComment,LikeSubComment,
		Send Invitation link
	*/
	PROFILE:{
		UPDATE_PROFILE: PREFIX.MYWOW + '/update-user-profile',
		CREATE_NEW_POST: PREFIX.MYWOW + '/posts/create-post',
		UPDATE_USER_POST: PREFIX.MYWOW + '/user/update_post/',
		DELETE_POST: PREFIX.MYWOW + '/user/delete_post/',
		GET_USER_DATA: PREFIX.MYWOW + '/auth/me',
		GET_USER_POST_DATA: PREFIX.MYWOW + '/user/posts',

		CREATE_LINK_INVITE_USER: PREFIX.MYWOW + '/create-invite-link',
		
		LIKE_POST: PREFIX.MYWOW + '/user/like_post/',
		MAKE_USER_COMMENT: PREFIX.MYWOW + '/user/add_comment',
		UPDATE_USER_COMMENT: PREFIX.MYWOW + '/user/update_comment/',
		DELETE_USER_COMMENT: PREFIX.MYWOW + '/user/delete_comment/',
		LIKE_USER_COMMENT: PREFIX.MYWOW + '/user/comment_like/',
		MAKE_USER_SUBCOMMENT: PREFIX.MYWOW + '/user/add_sub_comment',
		UPDATE_USER_SUBCOMMENT: PREFIX.MYWOW + '/user/update_sub_comment/',
		DELETE_USER_SUBCOMMENT: PREFIX.MYWOW + '/user/delete_sub_comment/',
		LIKE_USER_SUBCOMMENT: PREFIX.MYWOW + '/user/like_sub_comment/',
		SEND_INVITE: PREFIX.MYWOW + '/invite_user',
		GET_LINK_PREVIEW: PREFIX.MYWOW + '/link-preview',
		FOLLOW: PREFIX.MYWOW + '/user/follow_user/',
		UNFOLLOW: PREFIX.MYWOW + '/user/un_follow_user/',
		GET_ALL_KEEP_TOUCH: PREFIX.MYWOW + '/user/get-all-keep-in-touch',
		INCREASE_VIEW_COUNT: PREFIX.MYWOW + '/user/add_view/',
		GET_ALL_RELATION: PREFIX.MYWOW + '/user/profile/get-user-relationships',
		GET_ALL_PENDING_RECEIVED_REQUEST: PREFIX.MYWOW + '/user/pending-received-request',
		GET_ALL_SENT_REQUEST: PREFIX.MYWOW + '/user/pending-sent-request',
		ACCEPT_OR_REJECT_REQUEST:PREFIX.MYWOW + '/user/accept_reject_request/',
		GET_ALL_VIEWS:PREFIX.MYWOW + '/user/profile/all-view-list',
		GET_ALL_NOTIFICATIONS: PREFIX.MYWOW + '/get-all-notifications',
		READ_NOTIFICATIONS: PREFIX.MYWOW + '/notification-status-update',
		MESSAGE_NOTIFICATIONS: PREFIX.MYWOW + '/message-status-update',
	},

	/**
	 * Profile By ID
	 */
	SINGLE_PROFILE:{
		GET_SINGLE_USER_STREAM_LEFT: PREFIX.MYWOW + '/view-profile/stream-left/',
		GET_SINGLE_USER_STREAM_MIDDLE: PREFIX.MYWOW + '/view-profile/stream-middle/',
		GET_SINGLE_USER_BIO_RIGHT: PREFIX.MYWOW + '/view-profile/bio-right/',
		GET_SINGLE_USER_BIO_ABOUT: PREFIX.MYWOW + '/view-profile/about/',
		GET_SINGLE_USER_BIO_EDUCATION: PREFIX.MYWOW + '/view-profile/get-education/',
		GET_SINGLE_USER_BIO_WORKHISTORY: PREFIX.MYWOW + '/view-profile/get-work-history/',
		GET_SINGLE_USER_BIO_SKILLS: PREFIX.MYWOW + '/view-profile/get-skills/',
		GET_SINGLE_USER_RELATIONSHIPS: PREFIX.MYWOW + '/view-profile/relationship-middle/',
	},
	/**
	 * Profile By ID
	 */
	PROFILEBYID:{
		GET_SINGLE_POST: PREFIX.MYWOW + '/user/get-post/',
	},
	/* 
	* Stream Left Section
	*/
	STREAM:{
		LEFT_SECTION: PREFIX.MYWOW + '/user/profile/stream/left',
		MIDDLE_SECTION: PREFIX.MYWOW + '/user/profile/stream/middle',
		RIGHT_SECTION: PREFIX.MYWOW + '/user/profile/stream/right',
	},

	/****** Search API  *****/
	SEARCH:{
		SEARCH_DATA: PREFIX.MYWOW + '/user/search',
		SEARCH_SUGGEST: PREFIX.MYWOW + '/user/random-search-suggestion',
	},

	/* 
	* BIO Left Section
	*/
	BIO:{
		LEFT_SECTION: PREFIX.MYWOW + '/user/profile/bio/left',
		RIGHT_SECTION: PREFIX.MYWOW + '/user/profile/bio/right',
		USER_ANALYTICS: PREFIX.MYWOW + '/user/get-profile-view',
		RANDOM_ORGANISATION: PREFIX.MYWOW + '/user/random-organisations',
	},
	/* 
	* Improve About GET
	*/
	ABOUT:{
		GET_ABOUT: PREFIX.MYWOW + '/user/profile/about',
		EDIT_ABOUT: PREFIX.MYWOW + '/user/add_update_user_profile',
		EDIT_ABOUT_NEW: PREFIX.MYWOW + '/user/add_update_about_me',
	},
	/* 
	* Improve Education GET,ADD,EDIT,DELETE
	*/
	EDUCATION:{
		GET_EDUCATION: PREFIX.MYWOW + '/user/profile/get-education',
		ADD_EDUCATION: PREFIX.MYWOW + '/user/add_education',
		EDIT_EDUCATION: PREFIX.MYWOW + '/user/update_education/',
		REMOVE_EDUCATION: PREFIX.MYWOW + '/user/delete_education',
	},
	/* 
	* Work History GET,ADD,EDIT,DELETE
	*/
	WORK_HISTORY:{
		GET_WORK_HISTORY: PREFIX.MYWOW + '/user/profile/get-work-history',
		ADD_WORK_HISTORY: PREFIX.MYWOW + '/user/add_work_history',
		EDIT_WORK_HISTORY: PREFIX.MYWOW + '/user/update_work_history/',
		REMOVE_WORK_HISTORY: PREFIX.MYWOW + '/user/delete_work_history',
		GET_POSITIONS: PREFIX.MYWOW + '/admin/job-title',
	},
	/* 
	* Volunteer GET,ADD,EDIT,DELETE
	*/
	VOLUNTEER:{
		GET_VOLUNTEER: PREFIX.MYWOW + '/user/get_user_volunteer_experience',
		ADD_VOLUNTEER: PREFIX.MYWOW + '/user/add_volunteer_experience',
		EDIT_VOLUNTEER: PREFIX.MYWOW + '/user/update_volunteer_experience/',
		DELETE_VOLUNTEER: PREFIX.MYWOW + '/user/delete_volunteer_experience/',
	},
	/* 
	* Assignment
	*/
	ASSIGNMENT:{
		GET_STUDENT_ASSIGNMENT_LIST: PREFIX.MYWOW + '/discover/get-all-assignments',
		GET_STUDENT_ASSIGNMENT_BY_ID: PREFIX.MYWOW + '/discover/get-assignments/',
		GET_ASSIGNMENT_RIGHT_SIDE: PREFIX.MYWOW + '/discover/get-assignment-right',
		ADD_ASSIGNMENT_FILTER: PREFIX.MYWOW + '/discover/assignment-filter',
		UPDATE_ASSIGNMENT_FILTER: PREFIX.MYWOW + '/discover/assignment-filter/',
		GET_ALL_ASSIGNMENT_FILTER: PREFIX.MYWOW + '/discover/assignment-filters',
		DELETE_ASSIGNMENT_FILTER: PREFIX.MYWOW + '/discover/assignment-filter/',
		GET_SINGLE_ASSIGNMENT_DATA: PREFIX.MYWOW + '/get_assignment_by_id',
		GET_SELECT_FILTER_ASSIGNMENT: PREFIX.MYWOW + '/discover/get-assignments/',
	},
	/**
	 * Admin Calling
	 */
	ADMIN:{
		INTEREST_APPLY_API: PREFIX.MYWOW + '/user/interest_apply/',
		ASSIGNMENT_TYPE_LIST: PREFIX.MYWOW + '/admin/assignmentType',
		WORK_MODEL_LIST: PREFIX.MYWOW + '/admin/workModel',
		EDUCATION_LEVEL_LIST: PREFIX.MYWOW + '/admin/levelOfEducation',
		JOB_ROLE_DEPARTMENT: PREFIX.MYWOW + '/admin/department',
		GET_PAYMENT_INTERVAL: PREFIX.MYWOW + '/admin/payment-interval',
		GET_CURRENCY: PREFIX.MYWOW + '/admin/currency',
		GET_COUNTRY: PREFIX.MYWOW + '/admin/getCountry',
		GET_HARD_SKILLS: PREFIX.MYWOW + '/admin/hard-skills',
		GET_SOFT_SKILLS: PREFIX.MYWOW + '/admin/soft-skills',
	},

	/***
	 * 
	 * Chat Calling
	 */

	CHAT:{
		INITIATE_CHAT_MESSAGE: PREFIX.MYWOW + '/messages',
		MESSAGE_BY_ID: PREFIX.MYWOW + '/messages/',
		GET_ALL_INITIATED_CHAT: PREFIX.MYWOW + '/chat-list',
		GET_USER_DETAIL_ACTIVE_STATUS: PREFIX.MYWOW + '/user-online-status/',
		GET_UPDATE_CHAT_MESSAGE: PREFIX.MYWOW + '/message-update/',
		GET_DELETE_CHAT_MESSAGE:PREFIX.MYWOW + '/message/',
		GET_ALL_CHAT_CONNECTIONS: PREFIX.MYWOW + '/get-all-connections/'
	},
	/* 
	* Skills GET,ADD,UPDATE
	*/
	USER_SKILLS:{
		ADD_UPDATE_SKILLS: PREFIX.MYWOW + '/user/add_update_user_skills',
		GET_SKILLS: PREFIX.MYWOW + '/user/profile/get-skills',
	},
	/* 
	* Opportunity GET LIST,GET OPPORTUNITY AREA
	*/
	OPPORTUNITY:{
		GET_OPPORTUNITY_LIST: PREFIX.MYWOW + '/admin/opportunities',
		ADD_OPPORTUNITY_AREA: PREFIX.MYWOW + '/user/add_opportunity',
	},
	/**
	 * Employee URL'S
	 */
	CAREER_PORTAL_APPEARANCE:{
		GET_TALENT_FILTER: PREFIX.MYWOW + '/admin/pipeline',
		GET_INDUSTRY_DATA: PREFIX.MYWOW + '/admin/industry',
		GET_REVENUE_DATA: PREFIX.MYWOW + '/admin/revenue-status',
		GET_BUSINESS_DATA: PREFIX.MYWOW + '/businessTypes',
		GET_PERKS_BENEFITS: PREFIX.MYWOW + '/admin/perks-and-benefit',
		GET_GROWTH_STAGES: PREFIX.MYWOW + '/admin/growth-stage',
		GET_FUNDING_STAGES: PREFIX.MYWOW + '/admin/funding-stage',
		GET_PERKS_ICONS: PREFIX.MYWOW + '/admin/perks-icon',
		GET_VIEW_PORTAL_CODE: PREFIX.MYWOW + '/generateCode',
		GET_VIEW_PORTAL_CODE_BY_ID: PREFIX.MYWOW + '/cp-detail',
		GET_UNITED_NATION: PREFIX.MYWOW + '/admin/sustainable-development-goals',
		GET_CAREER_PORTAL_DATA: PREFIX.MYWOW + '/career-portal',
		ADD_APPEARANCE_DATA: PREFIX.MYWOW + '/career-portal/appearance',
		ADD_MESSAGING_DATA: PREFIX.MYWOW + '/career-portal/messaging',
		ADD_KEY_FIGURE_DATA: PREFIX.MYWOW + '/career-portal/key_figures',
		ADD_SOCIAL_LINKS_DATA: PREFIX.MYWOW + '/career-portal/website',
		ADD_PERK_BENEFITS_DATA: PREFIX.MYWOW + '/career-portal/perks',
		DELETE_PERK_BENEFITS_DATA: PREFIX.MYWOW + '/perks',
		ADD_SOCIAL_GOALS_DATA: PREFIX.MYWOW + '/goals',
		 DELETE_START_UP_FILTER: PREFIX.MYWOW + '/discover/startup-filter/',
		 SAVE_FILTER: PREFIX.MYWOW + '/discover/startup-filter/',
		// GET_ALL_ENTERPRISE: PREFIX.MYWOW + '/discover/get-organisations/enterprise',
		// GET_SELECT_FILTER_DETAIL_ENTERPRISE: PREFIX.MYWOW + '/discover/get-organisations/enterprise/',
		 UPDATE_SAVED_FILTER_START_UP: PREFIX.MYWOW + '/discover/startup-filter/',
		// GET_SUGGESTION_ENTERPRISE_DATA: PREFIX.MYWOW + '/discover/get-organisation-right/enterprise',
		 SAVE_ENTERPRISE_FILTER: PREFIX.MYWOW + '/discover/startup-filter',
		 SINGLE_ORGANIZATION: PREFIX.MYWOW + '/company-details/',
		 GET_EMPLOYE_RANGE: PREFIX.MYWOW + '/admin/employees-range',
	},

	EXPLORE_START_UP:{
		GET_SAVED_FILTER_START_UP_DATA: PREFIX.MYWOW + '/discover/startup-filters/',
		GET_ALL_START_UP: PREFIX.MYWOW + '/discover/get-organisations/startup',
		GET_SELECT_FILTER_DETAIL_START_UP: PREFIX.MYWOW + '/discover/get-organisations/startup/',
		GET_START_UP_FILTER: PREFIX.MYWOW + '/discover/get-organisations/startup',
		GET_SUGGESTION_START_UP_DATA: PREFIX.MYWOW + '/discover/get-organisation-right/startup',
	},

	EXPLORE_ENTERPRISE:{
		 GET_SAVED_FILTER_ENTERPRISE_DATA: PREFIX.MYWOW + '/discover/startup-filters/',
		 GET_ALL_ENTERPRISE: PREFIX.MYWOW + '/discover/get-organisations/enterprise',
		 GET_SELECT_FILTER_DETAIL_ENTERPRISE: PREFIX.MYWOW + '/discover/get-organisations/enterprise/',
		 GET_SUGGESTION_ENTERPRISE_DATA: PREFIX.MYWOW + '/discover/get-organisation-right/enterprise',
	},

	EMPLOYEER_PIPELINE:  {
		GET_PIPELINE_BY_ID: PREFIX.MYWOW + '/pipeline-stage',
		ADD_PIPELINES_STAGES: PREFIX.MYWOW + '/pipeline'
	},
	IMAGE_UPLOAD: PREFIX.MYWOW + '/file-manager/upload'
}
