import React, { Component , Fragment } from "react";
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Spinner from "../components/Loader";
import { bool,object } from "prop-types";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { showSuccessMsg, showErrorMsg } from "../utils/notification";

import {
    updateProfile,
    uploadImage,
    getUserData
  } from "../modules/profile/actions";
  import { getIsUploadingImage, getUserDataListing, getIsUserData } from "../modules/profile/selectors";

const userProfileSchema = Yup.object().shape({
    fullname: Yup.string().required("Please enter your name"),
    username: Yup.string().required("Please enter user name"),
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
    phoneNumber: Yup.string().required("Please enter your phone number"),
    country: Yup.string().required("Please enter your country"),
    address: Yup.string().required("Please enter your address"),
    city: Yup.string().required("Please enter your city"),
    postCode: Yup.number().required("Please enter postcode"),
  });


class Account extends Component {
  state = {
    fullname: "",
    username: "",
    email: "",
    phoneNumber:"",
    country:"",
    address:"",
    city:"",
    postCode:"",
    description:"",
  };
    // Update User Profile
    updateUserProfile = (values, { setSubmitting, resetForm }) => {
        const { updateProfile, getUserPosts } = this.props;
        //let { linkPreviewData } = this.state;
        
        if (Object.keys(values).length < 2) {
        showErrorMsg("Please Enter Something!");
        setSubmitting(false);
        } else {
            updateProfile(values).then(
                (res) => {
                if (res.status) {
                    showSuccessMsg(res.message);
                    resetForm(this.getFormikInitVal());
                    setSubmitting(false);
                } else {
                    setSubmitting(false);
                    showErrorMsg(res.message);
                }
                },
                (err) => {
                setSubmitting(false);
                }
            );
        }
    };

    textArea = (props) => {
        // console.log('props', props)
        let { form, field, className, placeholder } = props;
        return (
          <textarea
            className={className}
            rows="4"
            cols="30"
            placeholder={placeholder}
            name={field.name}
           /*  onKeyUp={(event) => this.onChangeHandler(event, form.setFieldValue)} */
            onChange={(values) =>
              form.setFieldValue(field.name, values.target.value)
            }
            value={field.value}
            onClick={this.ActivatePost}
          />
        );
      };
      /* loadPosts = () => {
        const { page, per_page } = this.state;
        this.props.getUserData().then((res) => {
          if(res.status){
            let userData = res.data;
            this.setState({
              userData: [...this.state.post, ...postData],
              scrolling: false,
              totalPages: res.data.meta ? res.data.meta.total_pages : 0,
              totalPostCount: res.data.meta ? res.data.meta.total : 0,
            });
          }
          console.log(res, "user data"); 
          
        }).catch((err) => {
        //showErrorMsg("Something Went Wrong");
        });
      }; */
    getFormikInitVal = () => {

      this.props.getUserData().then((res) => {
        if(res.status){
          let userData = res.data.user;
        this.setState({
          fullname:  userData.name,
          scrolling: false,
          totalPages: res.data.meta ? res.data.meta.total_pages : 0,
          totalPostCount: res.data.meta ? res.data.meta.total : 0,
        });
          return {
            fullname: userData.name,
            username: userData.username,
            email: userData.email,
            phoneNumber:userData.name,
            country:userData.name,
            address:userData.name,
            city:userData.name,
            postCode:userData.name,
            description:userData.name,

        };

          /* this.setState({
            userData: [...this.state.post, ...postData],
            scrolling: false,
            totalPages: res.data.meta ? res.data.meta.total_pages : 0,
            totalPostCount: res.data.meta ? res.data.meta.total : 0,
          }); */
        }else{
          return {
            fullname: "Hello ",
            username: "",
            email: "",
            phoneNumber:"",
            country:"",
            address:"",
            city:"",
            postCode:"",
            description:"",

          };
        }
      }).catch((err) => {
      //showErrorMsg("Something Went Wrong");
      });

     // let { getUserDetails } = this.props;

     
     
      /* this.props
          .getUserDetails()
          .then((res) => {
            console.log(res,"hello component will mount")
          })
          .catch((err) => {
            
          }); */
      
      /* let { mode } = this.state;
        let { data } = this.props;
       
    
        if (mode === "Edit") {
          let { post_content, post_image, link } = data;
    
          return {
            post_content,
            post_image,
            collectLinks: [{ link }],
          };
        } */
        

      };


      // Loading Posts at Page Load
    componentDidMount() {
        //let page = this.props.location.pathname + this.props.location.search;
        //trackPage(page);
       // this.loadPosts();
        /* this.scrollListener = window.addEventListener(
        "scroll",
        (e) => {
            this.handleScroll(e);
        },
        true
        ); */
    }


    render() {

      /* let {
        profiles,
        profile_image,
        hero_header,
        latest_field_of_study,
        latest_institute_name,
        first_name,
        last_name,
        country,
        city,
        view_by_type,
        viewed_data,
        business_connections,
        people_connections
      } = userDetails; */

        return (
            <Fragment> 
                <Header />
                <Leftnav />
                <Rightchat />

                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/defaultsettings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Account Details</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 text-center">
                                            <figure className="avatar ms-auto me-auto mb-0 mt-2 w100"><img src="https://via.placeholder.com/300x300.png" alt="avater" className="shadow-sm rounded-3 w-100" /></figure>
                                            <h2 className="fw-700 font-sm text-grey-900 mt-3">Surfiya Zakir</h2>
                                            <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">Brooklyn</h4>    
                                            
                                        </div>
                                    </div>

                                    <Formik
                                        initialValues={this.getFormikInitVal()}
                                        validationSchema={userProfileSchema}
                                        onSubmit={this.updateUserProfile}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                        <Form>
                
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Your Name</label>
                                                    <Field
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Your Name"
                                                      name="fullname"
                                                    />
                                                    <ErrorMessage
                                                      name="fullname"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>        
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">User Name</label>
                                                    <Field
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="User Name"
                                                      name="username"
                                                    />
                                                    <ErrorMessage
                                                      name="username"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Email</label>
                                                    <Field
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Your Email Address"
                                                      name="email"
                                                    />
                                                    <ErrorMessage
                                                      name="email"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>        
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Phone</label>
                                                    <Field
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Phone Number"
                                                      name="phoneNumber"
                                                    />
                                                    <ErrorMessage
                                                      name="phoneNumber"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Country</label>
                                                    <Field
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Country"
                                                      name="country"
                                                    />
                                                    <ErrorMessage
                                                      name="country"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>        
                                            </div>

                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Address</label>
                                                    <Field
                                                        type="textarea"
                                                        name="address"
                                                        component={this.textArea}
                                                        placeholder="Address"
                                                        className="form-control mb-0 p-3 h100 bg-greylight lh-16"
                                                    />
                                                    <ErrorMessage
                                                        name="address"
                                                        component="div"
                                                        className="text-danger"
                                                    />   

                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">City</label>
                                                    <Field
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="City"
                                                      name="city"
                                                    />
                                                    <ErrorMessage
                                                      name="city"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>        
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Postcode</label>
                                                    <Field
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Postcode"
                                                      name="postCode"
                                                    />
                                                    <ErrorMessage
                                                      name="postCode"
                                                      component="div"
                                                      className="error-msg pl-2 text-danger"
                                                    />
                                                </div>        
                                            </div>

                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <input type="file" name="file" id="file" className="input-file" />
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                            <span className="js-fileName">Drag and drop or click to replace</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            

                                            <div className="col-lg-12">
                                                <button
                                                    className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                >
                                                    {isSubmitting && <Spinner size={true} />}
                                                    {!isSubmitting && <>Save</>}
                                                </button>
                                            </div>
                                        </div>
                                        </Form>
                                        )}
                                    </Formik>
                                    
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>            
                </div>

                <Popupchat />
                <Appfooter /> 
            </Fragment>
        );
    }
}

//export default Account;


//export default Createpost;


/**
 *  Define component PropTypes
 */
 Account.propTypes = {
    isUploadingImage: bool.isRequired,
    /* getUserDetails: object.isRequired, */

    isUserData: getIsUserData(),
    userData: getUserDataListing(),
  };
  
  /**
   *  Map redux state to component props
   */
  const mapStateToProps = createStructuredSelector({
    isUploadingImage: getIsUploadingImage(),
    //profile: getUserDetails(),
  });
  
  export default withRouter(
    connect(mapStateToProps, {
      uploadImage,
      updateProfile,
      //getUserDetails,
      getUserData,
    })(Account)
  );