import React,{Component} from 'react';
import * as Yup from "yup";
import { getUserId, getLocalStorage } from "../utils/helper";
import { showSuccessMsg, showErrorMsg } from "../utils/notification";
import { bool } from "prop-types";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";

import Spinner from "./Loader";
import LoadSkeleton from './Skeletons/LoadSkeleton';
import ReactTooltip from "react-tooltip";

import {
    updateUserPost,
    createNewPostArea,
    getUserPosts,
    uploadImage,
    getLinkPreview,
  } from "../modules/profile/actions";
  import { getIsUploadingImage } from "../modules/profile/selectors";

const userPostSchema = Yup.object().shape({
    post_content: Yup.string()
      .min(1, "Description should at least have 1 character.")
      .required("Required")
  });

class Createpost extends React.Component {

    state = {
        post_content: "",
        post_image: "",
        post_videos: "",
        check_in_address: "",
        user_id: getUserId(),
        userDetails: JSON.parse(getLocalStorage()),
        disabled: true,
        linkBox: this.props.link !== "" ? true : false,
        imagePreviewUrl: this.props.image ? this.props.image : "",
        file: "",
        mode: this.props.mode === "edit" ? "Edit" : "Add",
        /* linkPreviewData: this.props.data.link, 
        showPreview: this.props.data.link !== [] ? true : false,*/
        initialPreview: true,
        createPost: false,
      };

      
  getFormikInitVal = () => {
    let { mode } = this.state;
    let { data } = this.props;
    // console.log(data)

    if (mode === "Edit") {
      let { post_content, post_image, link } = data;

      return {
        post_content,
        post_image,
        collectLinks: [{ link }],
      };
    }
    return {
      post_content: "",
      post_image: "",
      collectLinks: [{ link: "" }],
    };
  };

  handleFileUpload = (event, setFieldValue) => {
    const { uploadImage, isUploadingImage } = this.props;
    if (isUploadingImage) return;
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("type", "post");
    formdata.append("files[0]", file, file.name);
    uploadImage(formdata).then((res) => {
      if (res.status) {
        const  files  = res.data;
        const image = files[0].file.url; 
         this.setState({
          file: files[0],
          imagePreviewUrl: `${files[0].file.url}`,
        }); 
        setFieldValue("post_image", `${image}`);
      } else {
        const error    = res.error;
        Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');

      }
    });
  };

  // Add New Post
  createUserPost = (values, { setSubmitting, resetForm }) => {
    const { updateUserPost, getUserPosts, createNewPostArea } = this.props;
    let { linkPreviewData } = this.state;
    var params = {
      type: "profile",
      post_content: values.post_content,
    };
    if (this.globallyUrls) {
      params.link = this.globallyUrls;
    }
    if (values.post_image) {
      params.post_image = values.post_image;
    } else {
      params.post_image = null;
    }
    if (Object.keys(params).length < 2) {
      showErrorMsg("Please Enter Something!");
      setSubmitting(false);
    } else {
        createNewPostArea(params).then(
            (res) => {
              if (res.success) {
                showSuccessMsg(res.message);
                resetForm(this.getFormikInitVal());
                setSubmitting(false);
               /* this.setState({
                    file: "",
                    imagePreviewUrl: "",
                    linkBox: false,
                });
                let page = 1;
                 this.props.loadPosts(values, linkPreviewData);
                this.props.getUserPosts(page);
                this.props.onClose(); */
            } else {
                setSubmitting(false);
                showErrorMsg(res.message);
            }
            },
            (err) => {
            setSubmitting(false);
            }
        );
    }
  };

  textArea = (props) => {
    // console.log('props', props)
    let { form, field, className, placeholder } = props;
    return (
      <textarea
        className={className}
        rows="4"
        cols="30"
        placeholder={placeholder}
        name={field.name}
       /*  onKeyUp={(event) => this.onChangeHandler(event, form.setFieldValue)} */
        onChange={(values) =>
          form.setFieldValue(field.name, values.target.value)
        }
        value={field.value}
        onClick={this.ActivatePost}
      />
    );
  };


    //Show Image 
    showImage = props => {
        const { form, field } = props;
        if (field.value) {
        return (
            <div className="position-relative" style={{ width: 'auto', justifyContent: 'flex-start', display: 'inline-block' }}>
            <img src={field.value} alt="imgToUpload" className="mb-2 mr-4 attachmentPreview" />
            <span>
                <button type='button' className="btn btn-danger button-cross btn-sm" onClick={(e) => this.removeImage(e, form.setFieldValue)} >
                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" className="svg-inline--fa fa-times fa-w-10 w-9" role="img" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
                </button>
            </span>
            </div>
        )
        }
        return null
    }

    // File Upload 
    fileUpload = props => {
        const { form } = props;
        return (
        <input type="file" className="imageUploadData" id="imageUploadData" onChange={event => this.handleFileUpload(event, form.setFieldValue)} />
        )
    }

    render() {
        let { isUploadingImage} = this.props;
        let { imagePreviewUrl, userDetails, linkPreviewData, createPost } = this.state;
        return (
            
            <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                <Formik
                    initialValues={this.getFormikInitVal()}
                    validationSchema={userPostSchema}
                    onSubmit={this.createUserPost}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                
                <div className="card-body p-0">
                    <a href="/" className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center"><i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Create Post</a>
                </div>
                <div className="card-body p-0 mt-3 position-relative">
                    <figure className="avatar position-absolute ms-2 mt-1 top-5"><img src="assets/images/user.png" alt="icon" className="shadow-sm rounded-circle w30" /></figure>
                    
                    <Field
                        type="textarea"
                        name="post_content"
                        component={this.textArea}
                        placeholder={`What's on your mind, ${userDetails.user_details.name}?`}
                        className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                      />
                      <ErrorMessage
                        name="post_content"
                        component="div"
                        className="text-danger"
                      />    

                
                </div>
                <div className="card-body d-flex p-0 mt-0">
                    
                    <label className="cursorPointer d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4" data-tip data-for="addvideo">
                        <Field as="file" className="imageUploadData" name="post_video" component={this.fileUpload} />
                        <i className="font-md text-danger feather-video me-2"></i><span className="d-none-xs">Video</span>
                    </label>
                    <ReactTooltip id="addvideo" place="bottom" effect="solid">
                      Add Video
                    </ReactTooltip> 
                    <label className="cursorPointer d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4" data-tip data-for="addpicture">
                        <Field as="file" className="imageUploadData" name="post_image" component={this.fileUpload} />
                        <i className="font-md text-success feather-image me-2"></i> <span className="d-none-xs">Photo</span>
                    </label>
                    <ReactTooltip id="addpicture" place="bottom" effect="solid">
                      Add Picture
                    </ReactTooltip>
    
                    <a href="#activity" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-warning feather-map-pin  me-2"></i><span className="d-none-xs">Check-In </span></a>
                    <div className="ms-auto pointer"  >
                        <button
                            type="submit"
                            className="border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl d-inline-block"
                            disabled={isSubmitting || isUploadingImage}
                        >
                            Post
                            {isSubmitting && (
                                <span className="spinner-border spinner-border-sm ml-2"></span>
                            )}
                        </button>
                    </div>
                </div>
                </Form>
            )}
          </Formik>

            </div>
        );
    }
}

//export default Createpost;


/**
 *  Define component PropTypes
 */
 Createpost.propTypes = {
    isUploadingImage: bool.isRequired,
  };
  
  /**
   *  Map redux state to component props
   */
  const mapStateToProps = createStructuredSelector({
    isUploadingImage: getIsUploadingImage(),
  });
  
  export default withRouter(
    connect(mapStateToProps, {
      uploadImage,
      updateUserPost,
      createNewPostArea,
      getUserPosts,
      getLinkPreview,
    })(Createpost)
  );