import React, { Component , Fragment } from "react";
import {  Link  } from "react-router-dom";
import HeaderLogo from "../components/HeaderLogo";
import HeaderNav from "../components/HeaderNav";



import { func } from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getToken,setLocalStorage,getLocalStorage } from "../utils/helper";
//import { getToken, isRouteAllow, getLocalStorage } from 'utils/helper';
import { showErrorMsg } from "../utils/notification";
import { socialCallback } from "../modules/socialLogin/actions";
import { login } from "../modules/auth/actions";

import Spinner from "../components/Loader";

const UserLoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Please Enter Your Email Address"),
    password: Yup.string().required("Please Enter Your Password"),
  });

class Login extends Component {

    componentWillMount() {
        const { history } = this.props;
        const token = getToken();
        const storage = getLocalStorage();
        if (history && token && storage) {
            history.push("/home");
            return;
        }
    }

    state = {
        passwordview: false,
        isLoading: false,
        userId: "",
        name: "",
        email: "",
        picture: "",
        code: "",
        errorMessage: "",
      };
      userLoginValuesSchema = {
        email: "",
        password: "",
      };

      loginWithUser = (values, { setSubmitting, resetForm }) => {
        const { login, history } = this.props;
        const { email, password } = values;
    
        login(email, password).then(
          (res) => {
            if (res.status) {
                setLocalStorage(res.data);
                setSubmitting(false);
                history.push("/home");
            } else {
                showErrorMsg(res.error);
                setSubmitting(false);
                return false; 
            }
          },
          (err) => {
            const error    = err.error;
            Object.keys(error).map((key, data) => key !== "debug" ? showErrorMsg(error[key][0]) : '');
            setSubmitting(false);
            return false; 
          }

         
        );
      };


    render() {
        const { history } = this.props;
        const { code, errorMessage } = this.state;
        return (
            <Fragment> 
                <div className="main-wrap">
                    
                    <div className="row">
                        <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                        style={{backgroundImage: `url("/assets/images/mywow/Login-Bg.jpg")`}}>
                            <HeaderLogo logoColor = "white" />
                        </div>
                        <div className="col-xl-7 vh-100 align-items-center  bg-white rounded-3 overflow-hidden">
                            <div className = " text-right" style={{height: '15vh'}}> 
                                <HeaderNav />
                            </div>
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">
                                    <h2 className="fw-700 display1-size display2-md-size mb-3">Login into <br />your account</h2>
                                    
                            <Formik
                                initialValues={this.userLoginValuesSchema}
                                validationSchema={UserLoginSchema}
                                onSubmit={this.loginWithUser}
                                >
                                {({ isSubmitting }) => (
                                    
                                    <Form >
                                        
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                            <Field
                                                type="text"
                                                name="email"
                                                className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                placeholder="Your Email Address"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="error-msg mt-0 my-1 pl-2 pb-0 text-danger"
                                            />
                                        
                                        </div>
                                        <div className="form-group icon-input mb-1">
                                            <Field
                                                type={this.state.passwordview ? "text" : "password"}
                                                name="password"
                                                className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                placeholder="Enter Password"
                                                onPasteCapture={this.handlePaste}
                                                />
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="error-msg mt-0 my-1 pl-2 pb-0 text-danger"
                                            />
                                           
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                        </div>
                                        <div className="form-check text-left mb-3">
                                            <input type="checkbox" className="form-check-input mt-2" id="exampleCheck5" />
                                            <label className="form-check-label font-xsss text-grey-500">Remember me</label>
                                            <Link to="/forgot" className="fw-600 font-xsss text-grey-700 mt-1 float-right">Forgot your Password?</Link>
                                        </div>
                                    
                                    
                                    <div className="col-sm-12 p-0 text-left">
                                        <div className="form-group mb-1">
                                           <button
                                                className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                                                disabled={isSubmitting}
                                                type="submit"
                                                >
                                                {isSubmitting && <Spinner size={true} />}
                                                {!isSubmitting && <>Log in</>}
                                            </button>
                                        </div>
                                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Dont have account <Link to="/register" className="fw-700 ms-1">Register</Link></h6>
                                    </div>
                                    <div className="col-sm-12 p-0 text-center mt-2">
                                        
                                        <h6 className="mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3">Or, Sign in with your social account </h6>
                                        <div className="form-group mb-1"><a href="/register" className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2"><img src="assets/images/icon-1.png" alt="icon" className="ms-2 w40 mb-1 me-5" /> Sign in with Google</a></div>
                                        <div className="form-group mb-1"><a href="/register" className="form-control text-left style2-input text-white fw-600 bg-twiiter border-0 p-0 "><img src="assets/images/icon-3.png" alt="icon" className="ms-2 w40 mb-1 me-5" /> Sign in with Facebook</a></div>
                                    </div>

                                    </Form>
                                    )}
                                    </Formik>

                                </div>
                            </div> 
                        </div>
                        
                    </div>
                </div>
            </Fragment>
        );
    }
}

//export default Login;


/**
 *  Define component PropTypes
 */
 Login.propTypes = {
    login: func.isRequired,
    socialCallback: func.isRequired,
  };
  
  /**
   *  Map redux state to component props
   */
  const mapStateToProps = createStructuredSelector({});
  
  export default connect(mapStateToProps, {
    login,
    socialCallback,
  })(Login);
  