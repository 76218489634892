/**
 * Import action creator constants
 */
import { fromJS } from 'immutable';
import { success, failure } from '../../utils/redux';
import {
	UPDATE_PROFILE,
	GET_PRACTICE,
	GET_USER_DATA,
	GET_USER_DETAILS,
	CANCEL_ALL_API_REQUESTS,
	GET_USER_POSTS,
	KEEP_IN_TOUCH,
	KEEP_IN_TOUCH_ALL,
	UPLOAD_IMAGE,
	GET_ALL_RELATIONS,
	GET_ALL_PENDING_REQUESTS,
	SEARCH_SUGGESTIONS,
	CHECK_ALL_VIEWERS,
	SEARCH_DATA,
	GET_RANDOM_ORGANISATIONS,
	GET_ALL_NOTIFICATIONS,
	INVITE_LINK_FOR_USER
} from './constants';

/**
 * Set intial state
 */
const initialState = fromJS({
	limit: 1000,
	page: 1,
	isPracice: [],
	userData: {},
	userDetails:{},
	isUserData: false,
	isUpdatingProfile: false,
	UserPosts: {},
	isUserPost: false,
	KeepTouchData: {},
	isKeepTouch: false,
	getAllKeepTouchData: [],
	isGetAllKeepTouch: false,
	isUploadingImage: false,
	relationsData: [],
	isRelationsData: false,
	pendingRequests: [],
	isPendingRequests: false,
	searchSuggestions: {},
	isSuggestionsData: false,
	viewerData: [],
	isViewerData: false,
	searchData:{},
	isSearchData:false,
	randomOrganisations:[],
	isRandomOrganisations:false,
	allNotifications:[],
	isNotifications:false,
	inviteLinkData: {},
	isInviteLinkDataFetching:false,
})

/**
 * Define the reducer with actions
 */
function profileReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_PROFILE:
			return fromJS({
				...state.toJS(),
				isUpdatingProfile: true,
			})

		case success(UPDATE_PROFILE):
		case failure(UPDATE_PROFILE):
			return fromJS({
				...state.toJS(),
				isUpdatingProfile: false,
			})

		case GET_USER_DATA:
			return fromJS({
				...state.toJS(),
				isUserData: true,
			})

		case success(GET_USER_DATA):
			const { data } = action.payload.data;
			return fromJS({
				...state.toJS(),
				userData: data,
				isUserData: false,
			})
		case failure(GET_USER_DATA):
			return fromJS({
				...state.toJS(),
				isUserData: false,
			})
		
		/* User Details case start  */
		case GET_USER_DETAILS:
			return fromJS({
				...state.toJS(),
				isUserDetails: true,
			})

		case success(GET_USER_DETAILS):
			const  userdetails  = action.payload.data;
			return fromJS({
				...state.toJS(),
				userDetails: userdetails,
				isUserDetails: false,
			})
		case failure(GET_USER_DETAILS):
			return fromJS({
				...state.toJS(),
				isUserDetails: false,
			})
		/* User Details case end */



		case INVITE_LINK_FOR_USER:
			return fromJS({
				...state.toJS(),
				isInviteLinkDataFetching: true,
			})

		case success(INVITE_LINK_FOR_USER):
			const linkData = action.payload.data;
			return fromJS({
				...state.toJS(),
				inviteLinkData: linkData,
				isInviteLinkDataFetching: false,
			})
		case failure(INVITE_LINK_FOR_USER):
			return fromJS({
				...state.toJS(),
				isInviteLinkDataFetching: false,
			})

		case GET_PRACTICE:
			return fromJS({
				...state.toJS(),
				isUpdatingProfile: true,
			})

		case success(GET_PRACTICE):
		case failure(GET_PRACTICE):
			return fromJS({
				...state.toJS(),
				isUpdatingProfile: false,
			})

		case CANCEL_ALL_API_REQUESTS:
			return fromJS({
				...initialState.toJS(),
			})

		case GET_USER_POSTS:
			return fromJS({
				...state.toJS(),
				isUserPost: true
			})
		case success(GET_USER_POSTS):
			return fromJS({
				...state.toJS(),
				UserPosts: action.payload.data.data.data,
				isUserPost: false
			})
		case failure(GET_USER_POSTS):
			return fromJS({
				...state.toJS(),
				isUserPost: false
			})

		case KEEP_IN_TOUCH:
			return fromJS({
				...state.toJS(),
				KeepTouchData: {},
				isKeepTouch: true
			})
		case success(KEEP_IN_TOUCH):
			return fromJS({
				...state.toJS(),
				KeepTouchData: action.payload.data.data,
				isKeepTouch: false
			})
		case failure(KEEP_IN_TOUCH):
			return fromJS({
				...state.toJS(),
				KeepTouchData: {},
				isKeepTouch: false
			})

		case KEEP_IN_TOUCH_ALL:
			return fromJS({
				...state.toJS(),
				isGetAllKeepTouch: true
			})
		case success(KEEP_IN_TOUCH_ALL):
			return fromJS({
				...state.toJS(),
				getAllKeepTouchData: action.payload.data.data,
				isGetAllKeepTouch: false
			})
		case failure(KEEP_IN_TOUCH_ALL):
			return fromJS({
				...state.toJS(),
				isGetAllKeepTouch: false
			})

		case UPLOAD_IMAGE:
			return fromJS({
				...state.toJS(),
				isUploadingImage: true,
			})

		case success(UPLOAD_IMAGE):
		case failure(UPLOAD_IMAGE):
			return fromJS({
				...state.toJS(),
				isUploadingImage: false,
			})

		case GET_ALL_RELATIONS:
			return fromJS({
				...state.toJS(),
				isRelationsData: true
			})
		case success(GET_ALL_RELATIONS):
			return fromJS({
				...state.toJS(),
				relationsData: action.payload.data.data,
				isRelationsData: false
			})
		case failure(GET_ALL_RELATIONS):
			return fromJS({
				...state.toJS(),
				isRelationsData: false
			})

		case GET_ALL_PENDING_REQUESTS:
			return fromJS({
				...state.toJS(),
				isPendingRequests: true
			})
		case success(GET_ALL_PENDING_REQUESTS):
			return fromJS({
				...state.toJS(),
				pendingRequests: action.payload.data.data,
				isPendingRequests: false
			})
		case failure(GET_ALL_PENDING_REQUESTS):
			return fromJS({
				...state.toJS(),
				isPendingRequests: false
			})

		case SEARCH_SUGGESTIONS:
			return fromJS({
				...state.toJS(),
				isSuggestionsData: true
			})
		case success(SEARCH_SUGGESTIONS):
			return fromJS({
				...state.toJS(),
				searchSuggestions: action.payload.data.data,
				isSuggestionsData: false
			})
		case failure(SEARCH_SUGGESTIONS):
			return fromJS({
				...state.toJS(),
				isSuggestionsData: false
			})


		case CHECK_ALL_VIEWERS:
			return fromJS({
				...state.toJS(),
				isViewerData: true
			})
		case success(CHECK_ALL_VIEWERS):
			return fromJS({
				...state.toJS(),
				viewerData: action.payload.data.data.viewed_data,
				isViewerData: false
			})
		case failure(CHECK_ALL_VIEWERS):
			return fromJS({
				...state.toJS(),
				isViewerData: false
			})

			
		case SEARCH_DATA:
			return fromJS({
				...state.toJS(),
				isSearchData: true
			})
		case success(SEARCH_DATA):
			return fromJS({
				...state.toJS(),
				searchData: action.payload.data.data,
				isSearchData: false
			})
		case failure(SEARCH_DATA):
			return fromJS({
				...state.toJS(),
				isSearchData: false
			})


		case GET_RANDOM_ORGANISATIONS:
			return fromJS({
				...state.toJS(),
				isRandomOrganisations: true
			})
		case success(GET_RANDOM_ORGANISATIONS):
			return fromJS({
				...state.toJS(),
				randomOrganisations: action.payload.data.data,
				isRandomOrganisations: false
			})
		case failure(GET_RANDOM_ORGANISATIONS):
			return fromJS({
				...state.toJS(),
				isRandomOrganisations: false
			})


		case GET_ALL_NOTIFICATIONS:
			return fromJS({
				...state.toJS(),
				isNotifications: true
			})
		case success(GET_ALL_NOTIFICATIONS):
			return fromJS({
				...state.toJS(),
				allNotifications: action.payload.data.data,
				isNotifications: false
			})
		case failure(GET_ALL_NOTIFICATIONS):
			return fromJS({
				...state.toJS(),
				isNotifications: false
			})

		default:
			return state;
	}
}

/**
 * Export the reducer
 */

export default profileReducer;