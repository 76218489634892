import { showErrorMsg } from "./notification";
import * as moment from "moment";
// Permissions arrays
import { student, employee } from "../configs/routesConfig";

const storageKeyName = "mywow-user";
const dateFormat = "DD-MMM-YYYY";
const chatFormat = "MMMM Do YYYY, h:mm:ss a";

/**
 * Handle API Error Reponse
 *
 * @param err
 */
export const httpHandleError = (error) => {
  /* error = {error,config, code, request, response } */
  try {
    var xhr = error.request;
    if (!xhr.response) {
      return Promise.reject({});
    }

    var err = JSON.parse(xhr.response);
    if (xhr && xhr.status && err) {
      switch (xhr.status) {
        case 400:
        case 401:
          logout();
          showErrorMsg(err.error);
          break;
        case 404:
          showErrorMsg(err.error);
          break;
        case 406:
          showErrorMsg(err.error);
          break;
        case 422: 
          if (err.error) {
            Object.keys(err.error).map((key, data) => key !== "debug" ? showErrorMsg(err.error[key][0]) : '');
          }
          break;

        default:
          showErrorMsg("An internal error occur");
      }
    } else {
      showErrorMsg("An internal error occur");
    }
    //return Promise.reject({});
  } catch (e) {
    return Promise.reject({});
  }
};

export const logout = () => {
  deleteLocalStorage();
  //window.location.replace("/login");
};

export const setLocalStorage = (data) => {
  if (!data) return;
  localStorage.setItem(storageKeyName, JSON.stringify(data));
};

export const getLocalStorage = () => {
  return localStorage.getItem(storageKeyName);
};

export const deleteLocalStorage = () => {
  return localStorage.removeItem(storageKeyName);
};

export const getToken = () => {
  let storage = getLocalStorage();
  if (storage) {
    storage = JSON.parse(storage) 
    const { access_token } = storage.auth;
    return access_token;
  }
  return null;
};

export const getUserId = () => {
  let storage = getLocalStorage();
  if (storage) {
    storage = JSON.parse(storage)
    const { id } = storage.user_details;
    return id;
  }
  return null;
};

export const getBucketDetails = () => {
  var param = {
    bucketName: "noustacks3",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_s3_accessKeyId,
    secretAccessKey: process.env.REACT_APP_s3_secretAccessKey,
  };
  return param;
};

export const getFormattedDate = (date) => {
  if (!date) return;
  return moment(date).format(dateFormat);
};

export const getFormattedDateTimeForChat = (date) => {
  if (!date) return;
  return moment(date).format(chatFormat);
};

export const isDisplayRoute = (path) => {
  const storage = getLocalStorage();
  if (storage) {
    const { role_type } = JSON.parse(storage);
    if (path.search(":") !== -1) {
      return false;
    }

    switch (role_type) {
      case "student":
        return student.includes(path);

      default:
        return false;
    }
  }
  return false;
};

export const isRouteAllow = (path) => {
  const storage = getLocalStorage();
  if (storage) {
    const { role_type } = JSON.parse(storage);
    switch (role_type) {
      case "student":
        return student.includes(path);

      default:
        return false;
    }
  }
  return false;
};

export const s3_config = (dir) => {
  return {
    bucketName: "noustacks3",
    dirName: dir,
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_s3_accessKeyId,
    secretAccessKey: process.env.REACT_APP_s3_secretAccessKey,
  };
};

export const defaultImage =
  "https://noustacks3.s3.ap-south-1.amazonaws.com/1608108844.png";

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export var loginRandomText = [
  'Hey, you re back!',
  'Hello again, friend.',
  'Nice to see you again!',
  'Welcome back. You were missed.',
  'Welcome back!',
  'Glad you re back.',
  'Hey! Nice to see you.',
  'Hey there. Welcome back.',
  'Well, hello there.',
];
