/**
 * Import action creator constants & dependencies
 */
import {
	UPDATE_PROFILE,
	GET_USER_DATA,
	GET_USER_DETAILS,
	UPLOAD_IMAGE,
	GET_USER_POSTS,
	CREATE_NEW_POST,
	LIKE_POST,
	KEEP_IN_TOUCH,
	UPDATE_POST,
	DELETE_POST,
	MAKE_USER_COMMENT,
	UPDATE_USER_COMMENT,
	DELETE_USER_COMMENT,
	LIKE_USER_COMMENT,
	MAKE_USER_SUBCOMMENT,
	UPDATE_USER_SUBCOMMENT,
	DELETE_USER_SUBCOMMENT,
	LIKE_USER_SUBCOMMENT,
	GET_LINK_PREVIEW,
	SEARCH_DATA,
	FOLLOW_USER,
	UNFOLLOW_USER,
	KEEP_IN_TOUCH_ALL,
	INCREMENT_USER_VIEW_COUNT,
	GET_ALL_RELATIONS,
	GET_ALL_PENDING_REQUESTS,
	ACCEPT_OR_REJECT_REQUEST,
	SEARCH_SUGGESTIONS,
	CHECK_ALL_VIEWERS,
	GET_RANDOM_ORGANISATIONS,
	GET_ALL_NOTIFICATIONS,
	READ_NOTIFICATIONS,
	INVITE_LINK_FOR_USER,
	GET_ALL_SENT_REQUESTS
} from './constants';
import { API_URLS } from '../../configs/urls';
import axios from 'axios';
import qs from 'qs/lib/index';
const cancelApiRequests = [];

/**
 *  Get Profile User Data 
 */
export function getUserData(data) {
	return async (dispatch, getState) => {
		const state = getState();
		const id = data;
		const params = {
			user_id: id
		}
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getUserDataBegin(
					API_URLS.PROFILE.GET_USER_DATA,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getUserDataBegin = (API_URLS, params, source) => ({
	type: GET_USER_DATA,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/**
 *  Get Profile User Data 
 */
export function linkCreateForInvite(params) {
	return async (dispatch, getState) => {
		const state = getState();
		
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				linkCreateForInviteBegin(
					API_URLS.PROFILE.CREATE_LINK_INVITE_USER,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const linkCreateForInviteBegin = (API_URLS, params, source) => ({
	type: INVITE_LINK_FOR_USER,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/**
 * Get Link Preview (Crawler)
 * 
 **/
export function getLinkPreview(params) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getLinkPreviewBegin(
					API_URLS.PROFILE.GET_LINK_PREVIEW,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getLinkPreviewBegin = (API_URLS, params, source) => ({
	type: GET_LINK_PREVIEW,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	},
	params
});

/**
 * 
 * @param { Follow Api eg:- ( user connect with anyone ) }  
 */

export function followUser(id) {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				followUserBegin(
					API_URLS.PROFILE.FOLLOW + id,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const followUserBegin = (API_URLS, source) => ({
	type: FOLLOW_USER,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/**
 * 
 * @param { UnFollow Api eg:- ( user cannot connect with that person ) }  
 */

export function unFollowUser(id) {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				unFollowUserBegin(
					API_URLS.PROFILE.UNFOLLOW + id,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const unFollowUserBegin = (API_URLS, source) => ({
	type: UNFOLLOW_USER,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/******** Get Search Data ********/

export function getSearchData(data) {
	return async (dispatch, getState) => {
		const state = getState();
		//const id = data;
		const params = {
			keyword: data
		}
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getSearchDataBegin(
					API_URLS.SEARCH.SEARCH_DATA,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getSearchDataBegin = (API_URLS, params, source) => ({
	type: SEARCH_DATA,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/**
 * Update Profile
 */
export function updateProfile(params) {
	return async (dispatch, getState) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				updateProfileBegin(
					API_URLS.PROFILE.UPDATE_PROFILE,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const updateProfileBegin = (API_URLS, params, source) => ({
	type: UPDATE_PROFILE,
	payload: {
		request: {
			url: API_URLS,
			method: 'PUT',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
})

/**
 *  Upload Image
 */
export function uploadImage(formData) {
	return async dispatch => {
		try {
			const response = await dispatch(
				uploadImageBegin(
					API_URLS.IMAGE_UPLOAD,
					formData
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}

			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const uploadImageBegin = (API_URL, params) => ({
	type: UPLOAD_IMAGE,
	payload: {
		request: {
			url: API_URL,
			method: 'post',
			data: params,
			headers: {
				accept: 'application/json',
				'content-type': 'application/x-www-form-urlencoded'
			}
		}
	}
});

/**
 * SEND INVITE TO FRIENDS
 */
export function sendInvite(params) {
	return async (dispatch, getState) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				sendInviteBegin(
					API_URLS.PROFILE.SEND_INVITE,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const sendInviteBegin = (API_URLS, params, source) => ({
	type: UPDATE_PROFILE,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
})


/**
 *  GET USER POSTS
 */
export function getUserPosts(page) {
	return async (dispatch, getState) => {
		const state = getState();
		const params = {
		}
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getUserPostsBegin(
					API_URLS.PROFILE.GET_USER_POST_DATA + '?page=' + page,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getUserPostsBegin = (API_URLS, params, source) => ({
	type: GET_USER_POSTS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


/*
 *  Create new post
 */

export function createNewPostArea(params) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				createNewPostAreaBegin(
					API_URLS.PROFILE.CREATE_NEW_POST,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const createNewPostAreaBegin = (API_URLS, params, source) => ({
	type: CREATE_NEW_POST,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: params,
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/*
 *  Update The User Post
 */

export function updateUserPost(params, paramsId) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				updateUserPostBegin(
					API_URLS.PROFILE.UPDATE_USER_POST + paramsId,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const updateUserPostBegin = (API_URLS, params, source) => ({
	type: UPDATE_POST,
	payload: {
		request: {
			url: API_URLS,
			method: 'put',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
				// 'Content-type': 'application/x-www-form-urlencoded',
			},
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/*
 *  Delete Post 
 */

export function deletePost(paramsId) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				deletePostBegin(
					API_URLS.PROFILE.DELETE_POST + paramsId,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const deletePostBegin = (API_URLS, source) => ({
	type: DELETE_POST,
	payload: {
		request: {
			url: API_URLS,
			method: 'delete',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			// paramsSerializer: function(params) {
			// 	return qs.stringify(params, {arrayFormat: 'brackets'})
			// },
			cancelToken: source.token
		}
	}
});

/*
 *  Like a post
 */

export function LikePostArea(params) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				LikePostAreaBegin(
					API_URLS.PROFILE.LIKE_POST + params,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const LikePostAreaBegin = (API_URLS, params, source) => ({
	type: LIKE_POST,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: params,
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});




/*
 *  Make A Comment
 */

export function makeUserComment(params) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				makeUserCommentBegin(
					API_URLS.PROFILE.MAKE_USER_COMMENT,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const makeUserCommentBegin = (API_URLS, params, source) => ({
	type: MAKE_USER_COMMENT,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: params,
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


/*
 *  Update The User Post Comment
 */

export function updateUserPostComment(updateParams, updateParamsId) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				updateUserPostCommentBegin(
					API_URLS.PROFILE.UPDATE_USER_COMMENT + updateParamsId,
					updateParams,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const updateUserPostCommentBegin = (API_URLS, params, source) => ({
	type: UPDATE_USER_COMMENT,
	payload: {
		request: {
			url: API_URLS,
			method: 'put',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
				// 'Content-type': 'application/x-www-form-urlencoded',
			},
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


/*
 *  Delete Comments 
 */

export function deleteUserComment(paramsId) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				deleteUserCommentBegin(
					API_URLS.PROFILE.DELETE_USER_COMMENT + paramsId,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const deleteUserCommentBegin = (API_URLS, source) => ({
	type: DELETE_USER_COMMENT,
	payload: {
		request: {
			url: API_URLS,
			method: 'delete',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/*
 *  Like a post
 */

export function LikeCommentArea(params) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				LikeCommentAreaBegin(
					API_URLS.PROFILE.LIKE_USER_COMMENT + params,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const LikeCommentAreaBegin = (API_URLS, params, source) => ({
	type: LIKE_USER_COMMENT,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: params,
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


/*
 *  Make A SubComment
 */

export function makeUserSubComment(params) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				makeUserSubCommentBegin(
					API_URLS.PROFILE.MAKE_USER_SUBCOMMENT,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const makeUserSubCommentBegin = (API_URLS, params, source) => ({
	type: MAKE_USER_SUBCOMMENT,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: params,
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});



/*
 *  Update The User Post Comment
 */

export function updateUserPostSubComment(updateParams, updateParamsId) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				updateUserPostSubCommentBegin(
					API_URLS.PROFILE.UPDATE_USER_SUBCOMMENT + updateParamsId,
					updateParams,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const updateUserPostSubCommentBegin = (API_URLS, params, source) => ({
	type: UPDATE_USER_SUBCOMMENT,
	payload: {
		request: {
			url: API_URLS,
			method: 'put',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
				// 'Content-type': 'application/x-www-form-urlencoded',
			},
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


/*
 *  Delete Comments 
 */

export function deleteUserSubComment(paramsId) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				deleteUserSubCommentBegin(
					API_URLS.PROFILE.DELETE_USER_SUBCOMMENT + paramsId,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const deleteUserSubCommentBegin = (API_URLS, source) => ({
	type: DELETE_USER_SUBCOMMENT,
	payload: {
		request: {
			url: API_URLS,
			method: 'delete',
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

/*
 *  Like a post
 */

export function LikeSubCommentArea(params) {
	return async (dispatch) => {
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				LikeSubCommentAreaBegin(
					API_URLS.PROFILE.LIKE_USER_SUBCOMMENT + params,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const LikeSubCommentAreaBegin = (API_URLS, params, source) => ({
	type: LIKE_USER_SUBCOMMENT,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			data: params,
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/**
 *  GET KEEP IN TOUCH AREA
 */
export function getKeepInTouchArea(page) {
	return async (dispatch, getState) => {
		const state = getState();
		const params = {
		}
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getKeepInTouchAreaBegin(
					API_URLS.STREAM.RIGHT_SECTION,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getKeepInTouchAreaBegin = (API_URLS, params, source) => ({
	type: KEEP_IN_TOUCH,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/**
 *  GET All KEEP IN TOUCH AREA
 */
export function getAllKeepInTouchData(params) {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getAllKeepInTouchDataBegin(
					API_URLS.PROFILE.GET_ALL_KEEP_TOUCH,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getAllKeepInTouchDataBegin = (API_URLS, params, source) => ({
	type: KEEP_IN_TOUCH_ALL,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/**
 *  INCREASE VIEW COUNT
 */
export function increaseViewCount(Id) {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				increaseViewCountBegin(
					API_URLS.PROFILE.INCREASE_VIEW_COUNT + Id,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const increaseViewCountBegin = (API_URLS, source) => ({
	type: INCREMENT_USER_VIEW_COUNT,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			cancelToken: source.token
		}
	}
});


/**
 *  GET All RELATIONS DATA
 */
export function getAllRelations(params) {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getAllRelationsBegin(
					API_URLS.PROFILE.GET_ALL_RELATION,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getAllRelationsBegin = (API_URLS, params, source) => ({
	type: GET_ALL_RELATIONS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


/**
 *  GET All PENDING REQUESTS
 */
export function getAllReceivedPendingRequests(params) {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getAllReceivedPendingRequestsBegin(
					API_URLS.PROFILE.GET_ALL_PENDING_RECEIVED_REQUEST,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getAllReceivedPendingRequestsBegin = (API_URLS, params, source) => ({
	type: GET_ALL_PENDING_REQUESTS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/**
 *  GET All SENT REQUESTS
 */
export function getAllSentRequests(params) {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getAllSentRequestsBegin(
					API_URLS.PROFILE.GET_ALL_SENT_REQUEST,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getAllSentRequestsBegin = (API_URLS, params, source) => ({
	type: GET_ALL_SENT_REQUESTS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


/**
 *  Accept or Reject pending requests
 */
export function acceptOrRejectRequest(Id,params) {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				acceptOrRejectRequestBegin(
					API_URLS.PROFILE.ACCEPT_OR_REJECT_REQUEST + Id,
					source,
					params
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const acceptOrRejectRequestBegin = (API_URLS, source, params) => ({
	type: ACCEPT_OR_REJECT_REQUEST,
	payload: {
		request: {
			url: API_URLS,
			method: 'POST',
			data: JSON.stringify(params),
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});


/**
 *  GET All SEARCH SUGGESTIONS
 */
export function getSearchSuggestions() {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getSearchSuggestionsBegin(
					API_URLS.SEARCH.SEARCH_SUGGEST,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getSearchSuggestionsBegin = (API_URLS, source) => ({
	type: SEARCH_SUGGESTIONS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


/**
 *  GET All VIEWERS 
 */
export function getAllViewers() {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getAllViewersBegin(
					API_URLS.PROFILE.GET_ALL_VIEWS,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getAllViewersBegin = (API_URLS, source) => ({
	type: CHECK_ALL_VIEWERS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/**
 *  GET RANDOM ORGANISATIONS
 */
export function getRandomOrganisation() {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getRandomOrganisationBegin(
					API_URLS.BIO.RANDOM_ORGANISATION,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getRandomOrganisationBegin = (API_URLS, source) => ({
	type: GET_RANDOM_ORGANISATIONS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});


/**
 *  GET USER POSTS
 */
export function getUserNotifications(page) {
	return async (dispatch, getState) => {
		const state = getState();
		const params = {
		}
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				getUserNotificationsBegin(
					API_URLS.PROFILE.GET_ALL_NOTIFICATIONS + '?page=' + page,
					params,
					source
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const getUserNotificationsBegin = (API_URLS, params, source) => ({
	type: GET_ALL_NOTIFICATIONS,
	payload: {
		request: {
			url: API_URLS,
			method: 'get',
			params: params,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'brackets' })
			},
			cancelToken: source.token
		}
	}
});

/**
 * 
 * @param { Follow Api eg:- ( user connect with anyone ) }  
 */

export function readNotification(params) {
	return async (dispatch, getState) => {
		const state = getState();
		const source = axios.CancelToken.source();
		cancelApiRequests.push(source);
		try {
			const response = await dispatch(
				readNotificationBegin(
					API_URLS.PROFILE.READ_NOTIFICATIONS,
					source,
					params
				)
			);
			if (response.payload) {
				const { data } = response.payload;
				return data;
			}
			throw response;
		} catch (error) {
			throw error.response;
		}
	};
}

export const readNotificationBegin = (API_URLS, source,params) => ({
	type: READ_NOTIFICATIONS,
	payload: {
		request: {
			url: API_URLS,
			method: 'post',
			params: params,
			headers: {
				accept: 'application/json',
				'content-type': 'application/json'
			},
			cancelToken: source.token
		}
	}
});

